.control-group {
  position: relative;
  margin: 0 0 16px 0;
  padding: 0 0 5px 0;
  .control-checkbox a {
    text-decoration: underline;
  }
}

.control-checkbox-text {
  display: block;
  max-height: 22px;
  max-width: 100%;
  overflow: hidden;
  font-family: @euclidRegular;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;

  + sup {
    position: absolute;
    top: 0;
    right: 0;
  }
  a {
    position: relative;
    z-index: 6;
  }
}

.control {
  display: block;
  position: relative;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-left: 30px;
  font-size: 16px;
  cursor: pointer;
}
.control input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 1px solid rgba(15, 54, 92, 0.2);
  border-radius: 0px;
}
.control:hover input ~ .control_indicator {
  background: #1f8af1;
}
.control input:focus ~ .control_indicator {
  background: #fff;
}

.control input:checked ~ .control_indicator {
  background: rgb(31 138 241);
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: rgb(31 138 241);
}
.control input:disabled ~ .control_indicator {
  opacity: 0.6;
  background: #e6e6e6;
  pointer-events: none;
}
.control_indicator:after {
  display: none;
  position: absolute;
  box-sizing: unset;
  content: '';
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  top: 3px;
  left: 6px;
  transform: rotate(45deg);
  height: 8px;
  width: 3px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  display: block;
  position: absolute;
  top: -1.6rem;
  left: -1.6rem;
  transform: scale(0);
  height: 4.5rem;
  width: 4.5rem;
  opacity: 0.6;
  background: rgb(31, 138, 241);
  border-radius: 3rem;
  z-index: 99999;
  content: '';
}

