.clientElevatoTest {
  display: flex;
  padding-top: 130px;

  .testImageSection {
    position: relative;
    width: 50%;

    .mainbg {
      position: absolute;
      top: -77%;
      right: -11%;
      width: 1320px;
      z-index: -1;
    }

    .baseImg {
      position: relative;
      top: -5px;
      left: -228px;
      width: 800px;
    }

    .shapeBase,
    .iconBase {
      position: absolute;
    }

    .shape1 {
      top: -76px;
      left: 175px;
      width: 29px;
      animation-duration: 6s;
      animation-name: float;
    }
    .shape2 {
      top: -85px;
      left: 226px;
      width: 180px;
      opacity: 0.2;
      z-index: -1;
    }

    .icon1 {
      top: -65px;
      left: 8px;
      width: 170px;
    }
    .icon2 {
      top: 15px;
      left: 359px;
      width: 150px;
    }
    .icon3 {
      top: -99px;
      left: 493px;
      width: 156px;
    }
    .icon4 {
      top: 239px;
      left: 431px;
      width: 133px;
    }
    .icon5 {
      top: 395px;
      left: 215px;
      width: 204px;
    }
  }

  .textSection {
    position: relative;
    padding-left: 90px;
    width: 50%;
    .shapeBase,
    .iconBase {
      position: absolute;
    }

    .shape3 {
      top: -252px;
      right: -336px;
      width: 321px;
      opacity: 0.2;
    }
    .shape4 {
      top: -69px;
      right: -77px;
      width: 147px;
      animation-duration: 6s;
      animation-name: float;
    }

    .testElevatoLogo {
      margin: 0 auto 0 0;
    }
    .mainHeader {
      margin-left: 43px;
      text-align: left;
    }
    .textBelow {
      margin-left: 43px;
      text-align: left;
    }
    .buttonCTA {
      margin: 0 auto 0 43px;
    }
  }
}

@media (max-width: 1550px) {
  .clientElevatoTest {
    .textSection {
      .shape3 {
        right: -120px;
      }
      .shape4 {
        right: 80px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .testElevato {
    padding-bottom: 0px;
  }
  .clientElevatoTest {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding-top: 0px;

    .textSection {
      display: flex;
      justify-content: center;
      margin-bottom: 80px;
      padding-left: 0px;
      width: 100%;

      .testElevatoLogo {
        margin: 0 auto;
      }
      .mainHeader {
        margin-left: 0px;
        text-align: center;
      }
      .textBelow {
        margin-left: 0px;
        text-align: center;
      }
      .buttonCTA {
        margin: 0 auto;
      }

      .shape3 {
        top: -80px;
        right: -210px;
      }
      .shape4 {
        top: 70px;
        right: 50px;
      }
    }

    .testImageSection {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 500px;

      .mainbg {
        top: -77%;
        right: -3%;
        width: 1000px;
      }

      .baseImg {
        left: 0;
        height: 100%;
        width: 100%;
        max-width: 500px;
      }

      .shape1 {
        top: -60px;
      }
      .shape2 {
        top: -75px;
      }

      .icon1 {
        top: -52px;
        left: 93px;
        width: 100px;
      }
      .icon2 {
        top: 52px;
        left: 359px;
        width: 120px;
      }
      .icon3 {
        top: -49px;
        left: 390px;
        width: 130px;
      }
      .icon4 {
        top: 255px;
        left: 388px;
        width: 104px;
      }
      .icon5 {
        top: 218px;
        left: -1px;
        width: 158px;
      }
    }
  }
}

@media (max-width: 630px) {
  .clientElevatoTest {
    .testImageSection {
      .shape1 {
        top: -60px;
        left: 30%;
      }
      .shape2 {
        top: -70px;
        left: 35%;
      }
      .icon1,
      .icon2,
      .icon3,
      .icon4,
      .icon5 {
        display: none;
      }
    }
  }
}
