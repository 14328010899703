.joinUsHome {
  position: relative;
  .mainHeader {
    text-align: center;
  }
  .h4_container {
    position: relative;
  }
  .shapeBG {
    position: absolute;
    top: -270px;
    left: -43%;
    width: 700px;
    z-index: -1;
  }
  .shapeQuote {
    position: absolute;
    width: 259px;
    left: 96px;
    top: -80px;
  }

  .clientsButtonContainer {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    .clientsButton {
      margin-top: 32px;
      margin-bottom: 32px;
      max-width: 350px;
    }
  }
}

.owl-item {
  display: flex;
  justify-content: center;
  padding: 20px 0 30px 0;
}

.carouselWrap {
  margin: 45px auto 0 auto;
  .carouselItem {
    min-width: 550px;
    max-width: 550px;
    width: 100%;
    padding: 45px 45px 13px 45px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > p {
      font-size: 16px;
      margin: 0 0 12px 0;
      &:last-of-type {
        margin: 0;
      }
    }
    > div > p {
      margin: 0 0 12px 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .quoteDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: 35px 0 0 0;
    border-top: 1px solid #dde7ed;
    p {
      font-style: italic;
      font-size: 1.125rem;
    }
    img {
      max-width: 152px;
      margin: 0 0 0 30px;
    }
  }
}

@media (max-width: 660px) {
  .joinUsHome {
    .shapeBG {
      display: none;
    }
    .shapeQuote {
      width: 200px;
      left: -30px;
      right: 0;
    }
  }
  .carouselWrap {
    margin: 45px auto 0 auto;
    .carouselItem {
      min-width: 400px;
      max-width: 400px;
    }
    .quoteDetails {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      img {
        margin: 0px auto;
      }
      p br {
        display: none;
      }
    }
  }
}

.carouselWrap.owl-carousel .owl-stage {
  display: flex;
  flex-direction: row;
}

.carouselWrap.owl-carousel .owl-stage::after {
  display: none;
}

.carouselWrap.owl-carousel .owl-item {
  float: none;
}

@media (max-width: 500px) {
  .carouselWrap {
    margin: 15px auto 0 auto;
    .carouselItem {
      min-width: 340px;
      max-width: 340px;
      padding: 20px 20px 13px 20px;
    }
  }
}

@media (max-width: 380px) {
  .carouselWrap {
    .carouselItem {
      min-width: 280px;
      max-width: 280px;
    }
  }
}
