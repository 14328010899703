.eventsContainer {
  padding: 50px 20px 0 20px;
  h1 {
    text-align: center;
  }
  h2 {
    text-align: center;
  }
}

.eventsArticleWrap {
  padding: 50px 0 0 0;
}

.eventItem {
  max-width: 600px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  .postDate {
    opacity: 0.8;
    color: #0f365c;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.27px;
    line-height: 18px;
    padding: 0px 0 10px 0;
    display: flex;
    align-items: center;
  }

  .heading {
    color: #0f365c;
    font-family: @euclidBold;
    letter-spacing: -0.66px;
    margin: 0;
    text-align: left;
    line-height: 1.1;
    padding: 0 0 10px 0;
  }
}

.postContent {
  color: #0f365c;
  font-size: 18px;
  letter-spacing: -0.35px;
  line-height: 26px;
  margin: 10px 0 0 0;
  p {
    color: #0f365c;
    font-size: 18px;
    letter-spacing: -0.35px;
    line-height: 26px;
  }
}

.postGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
  z-index: 1;
  background: linear-gradient(
    155.82deg,
    rgba(124, 208, 255, 0.36) 0%,
    rgba(0, 157, 245, 0.81) 100%
  );
}

.postImageWrapper {
  height: 198px;
  width: 555px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin: 10px 0 0 0;
}

.postImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  transform: translateY(-25%);
  transition: 256ms ease-in-out;
}

.postItem a:hover .postImage {
  transform: translateY(-25%) scale(1.2);
}

.heroContainer {
  position: relative;
  max-width: 1340px;
  z-index: -1;
  .herobg {
    display: block;
    position: absolute;
    top: initial;
    bottom: -490px;
    width: 1000px;
    right: -20%;
    z-index: -1;
  }
  .shape2 {
    width: 300px;
    right: -20%;
    bottom: -760px;
    opacity: 0.1;
    animation-duration: 8s;
  }
  .shape3 {
    width: 120px;
    right: -2%;
    bottom: -600px;
    opacity: 1;
    animation-duration: 8s;
  }
  .shapeBase {
    position: absolute;
    animation-duration: 6s;
    animation-name: float;
  }
}

#webinarSeeMore {
  display: none;
}

.readMore {
  padding: 0 0 30px 0;
  margin: 20px 0 40px 0;
  border-bottom: 1px solid #dde7ed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    color: #00baff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.27px;
    line-height: 18px;
  }
  a {
    display: flex;
    align-items: center;
  }
  svg {
    margin: 3px 0 0 5px;
    width: auto;
    height: 12px;
    color: #00baff;
  }
}

@media (max-width: 991px) {
  .h4_container .sidebarWrapper {
    max-width: 500px;
  }
  .h4_inner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .postImageWrapper {
    width: 100%;
  }

  .h4_container .contentWrapper {
    max-width: initial;
    width: 100%;
  }

  .h4_container .sidebarWrapper {
    position: static;
    width: 100%;
    margin: 0px auto 0 auto;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@media (max-width: 1000px) {
  .heroContainer {
    overflow: hidden;
  }
  .eventsArticleWrap {
    padding: 0;
  }
}
@media (max-width: 630px) {
  .shape2,
  .shape3 {
    display: none;
  }
  .eventItem .heading {
    line-height: 1.2;
  }
  .eventItem {
    .heading {
      font-size: 1.875rem;
    }
  }
}
