.heroPricing {
  padding: 26px 0 0 0;
  .h4_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .girl {
      position: absolute;
      bottom: 0px;
      left: -10%;
      transform: translateX(-50%);
      max-height: 490px;
      width: auto;
      pointer-events: none;
      z-index: 2;
    }
    .shapeBase,
    .shapeOutline {
      position: absolute;
      z-index: -1;
    }
    .shapeOutline {
      right: -27px;
      bottom: 35%;
      width: 150px;
    }
    .shape1 {
      right: -8px;
      bottom: 2%;
      width: 112px;
    }
    .shape2 {
      right: -43px;
      bottom: 7%;
      width: 24px;
    }
    .shape3 {
      top: 27%;
      right: 45%;
      width: 18px;
    }
    .shape4 {
      right: -257px;
      bottom: 16%;
      width: 317px;
      opacity: 0.1;
    }
    .shape5 {
      bottom: 47%;
      left: 4%;
      width: 60px;
    }
  }

  .pricingPageTitle {
    max-width: 700px;
    color: #0f365c;
    font-family: @euclidBold;
    font-size: 2.125rem;
    line-height: 1.3;
    letter-spacing: -0.82px;
    text-align: center;
  }

  .pricingImageContainer {
    display: flex;
    position: relative;
    width: 100%;

    .herobg {
      position: absolute;
      right: -26%;
      bottom: -358px;
      z-index: -1;
    }
  }

  .pricingPlan {

    margin-top: 60px;
    margin-bottom: 0px;
    width: 100%;

    .pricingPlanToggle {
      margin: 20px auto 40px auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      /* The switch - the box around the slider */
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin: auto 20px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #2196F3;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked+.slider {
        background-color: #2196F3;
      }

      input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .pricingPlanPlans {
      display: flex;
      align-items: stretch;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 20px;
    .pricingHeaderWrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      padding: 17px 28px 0px 28px;
    }
    .pricingFeaturesWrap {
      padding: 0px 28px 55px 28px;
    }

    .pricingPlanLite,
    .pricingPlanPro, .pricingPlanCustom {
      // padding: 17px 28px 33px 28px;
      width: 100%;
      max-width: 368px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 12px;
      box-shadow: 0 6px 28px 0 #DBECF7;
    }

    .pricingPlanHorizontalLine {
      margin: 16px 0px 0px;
      height: 6px;
      width: 100%;
      border-radius: 2px;
    }

    .pricingPlanName {
      color: rgba(0, 157, 245, 1);
      font-family: @euclidBold;
      /*font-size: 1.375rem;*/
      font-size: 2.375rem;
      font-weight: bold;
      letter-spacing: -0.43px;
      text-align: center;
      margin: 30px auto 10px auto;
    }
    .pricingPlanDescription {
      font-family: @euclidRegular;
      font-weight: normal;
      font-style: italic;
      text-align: center;
      margin: 20px 0px 30px 0px;
    }
    .pricingPlanPrice {
      color: rgba(15, 54, 92, 1);
      font-family: @euclidBold;
      font-size: 2.375rem;
      letter-spacing: -0.82px;
      text-align: center;
      margin: 0px auto 10px auto;
    }
    &.yearly { 
      .pricingPlanPrice {
        //margin-left: -30px;
        .monthly {
          color: rgb(85, 85, 85);
          text-decoration: line-through;
          font-size: 0.7em;
          font-weight: normal;
          font-family: @euclidRegular;
          margin-right: 16px;
        }
        .yearly {
          display: inline-block;
        }
      }
      .pricingPlanPriceInfo {
        .monthly {
            display: none;
        }
      }
    }
    &.monthly {
      .pricingPlanPrice {
        //margin-left: 0px;
        .monthly {
          display: inline-block;
        }
        .yearly {
          display: none;
        }
      }
      .pricingPlanPriceInfo {
        .yearly {
          display: none;
        }
      }
    }
    &.yearly {
      .pricingPlanPriceInfo {
        .monthly {
          display: none;
        }
      }
    }
    .pricingPlanPriceInfo {
      /*color: rgb(75, 75, 75);*/
      color: #9b9b9b;
      font-family: @euclidRegular;
      font-size: 0.8rem;
      text-align: center;

      span {
        display: block;
      }
    }
    .pricingPlanList {
      padding: 0px 0px 17px;
      list-style: none;

      .prigingPlanListItem {
        display: flex;
        align-items: flex-start;
        padding: 0px 0px 7px 40px;
        width: 100%;
        color: rgba(15, 54, 92, 1);
        // font-family: @euclid;
        font-family: @euclidRegular;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: -0.35px;
        &:before {
          display: inline-block;
          margin-top: 2px;
          margin-left: -40px;
          height: 16px;
          width: 16px;
          color: rgba(131, 214, 63, 1);
          background-image: url(../../../assets/img/check.svg);
          background-size: 16px 16px;
          background-repeat: no-repeat;
          content: '';
        }
        .pricingPlanFeatuesInfo {
          font-weight: bold;
        }
        span {
          margin: 0 0 0 15px;
          max-width: 100%;
          font-weight: normal;
        }
        .pricingSpecial {
          margin-left: 3px;
          &.unlimited {
            color: green;
          }
          &.comingSoon {
            color: green;
          }
        }
        &.cleanup {
          height: 38px;
          &:before {
            content: none;
          }
        }
        &.header {
          padding-left: 8px;
        }
        &.header:before {
          background: none;
        }
        .pricingPlanPriceAccount {
          color: rgb(255, 0, 0);
          margin-left: 0px;
        }
      }
    }


    .pricingRightList {
      padding-left: 30px;
    }   
  }

  .h4_btn {
    width: 100%;
    max-width: 100%;
  }

  .pricingPlanLite {
    margin-top: 50px;
    //margin: 50px 20px 0px 0px;

    .pricingPlanName {
      color: rgba(0, 157, 245, 1);
    }
    .pricingPlanHorizontalLine {
      background-color: rgba(0, 157, 245, 1);
    }
  }
  .pricingPlanPro {
    margin-top: 0px;
    //margin: 0px 20px 0px 0px;
    z-index: 1;
    position: relative;
    border-radius: 0px 0px 0px 0px;

    .pricingPlanHorizontalLine {
      margin: 15px 0px 24px;
    }
    .pricingPlanName {
      color: rgba(131, 214, 63, 1);
      margin-top: 80px;
    }
    .pricingPlanHorizontalLine {
      background-color: rgba(131, 214, 63, 1);
    }
  }
  .pricingPlanPro:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 12px 12px 0px 0px;
    content: "";
    background-color: #009df5;
  }
  .pricingPlanCustom {
      margin-top: 50px;
      //margin: 50px 0px 0px 0px;
  
      .pricingPlanHorizontalLine {
        margin: 15px 0px 24px;
      }
  
      .pricingPlanName {
        color: rgb(184, 63, 214);
      }
  
      .pricingPlanHorizontalLine {
        background-color: rgb(184, 63, 214);
      }
      .pricingPlanPrice {
          font-size: 1.5em;
          line-height: 2em;
      }
    }
  }
  .pricingMostPopular {
    position: absolute;
    text-align: center;
    margin: 0px auto;
    top: 15px;
    color: #fff;
    width: 100%;
  }
  // .pricingPlanBtnContainer {
  //   padding: 0px;
  // }

  .pricingPlanBtnDetailsContainer {
    margin: 38px auto;
    text-align: center;
    max-width: 368px;
    width: 100%;

    .pricingDetailsLink.buttonLight {
      background: #009df5;
      color: #fff;

      svg {
        color: #fff;
      }
    }
    .pricingDetailsLink.buttonLight:hover {
      background: rgba(0, 157, 245, 0.12);
      color: #009df5;

      svg {
        color: #009df5;
      }
    }
  }
}



.heroPricingClients {
  .clientsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    background: #fff;
    border-top: 1px solid rgba(36, 147, 255, 0.15);
    z-index: 4;

    .clients {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      padding: 20px;
      width: 100%;
      z-index: 5;

      p {
        margin: 0px 32px 0 0;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: -0.35px;
        white-space: nowrap;
      }

      .clientsListItem {
        margin: 0 50px 0 0;
        max-width: 190px;
        transition: 0.3s ease-in-out;
        filter: grayscale(100%);

        &:hover {
          filter: grayscale(0%);
        }

        &:last-of-type {
          margin: 0;
        }

        & img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

.pricingDetails {
  padding: 52px 0px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .pricingDetailsTitle {
    color: rgba(15, 54, 92, 1);
    font-size: 2.625rem;
    font-family: @euclidBold;
    letter-spacing: -0.82px;
    line-height: 49px;
    text-align: center;
  }

  .pricingDetailsListContainer {
    padding-top: 62px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .pricingLeftList,
  .pricingRightList {
    ul {
      list-style: none;
      padding-left: 30px;
      margin: 0px;

      .pricingListItem {
        padding: 0px 0px 12px;
        color: rgba(15, 54, 92, 1);
        font-family: @euclidRegular;
        font-size: 1.125rem;
        line-height: 24px;
        letter-spacing: -0.35px;

        &:before {
          display: inline-block;
          margin-left: -30px;
          width: 30px;
          color: rgba(11, 157, 246, 1);
          content: '\2022';
          font-size: 2rem;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .pricingDetailsInfo {
    text-align: center;
    width: 100%;

    a {
      margin: 30px auto;
    }
  }
}

@media (max-width: 1170px) {
  .heroPricing {
    .pricingPlan {
      .pricingPlanLite,
      .pricingPlanPro {
        max-width: 376px;
      }
      .pricingPlanList {
        padding: 0px 20px 10px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .heroPricing {
    .pricingPlan {
      .pricingPlanLite,
      .pricingPlanPro {
        max-width: 356px;
      }
      .pricingPlanList {
        padding: 0px 10px 10px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .heroPricing {
    // .pricingPlan {
    //   .pricingPlanLite {
    //     margin-top: 50px;
    //     //margin: 0px 20px 50px 0px;
    //   }
    //   .pricingPlanPro {
    //     margin-top: 50px;
    //     //margin: 0px 0px 50px 20px;
    //   }
    // }
    .h4_container {
      .girl {
        max-height: 350px;
      }
    }
  }
  .heroPricingClients {
    .clientsContainer {
      .clients {
        flex-wrap: wrap;
        p {
          display: block;
          margin: 0;
          width: 100%;
          text-align: center;
        }

        .clientsListItem {
          margin: 0 auto;
          &:last-of-type {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
@media (max-width: 1665px) {
  .heroPricing {
    .h4_container {
      .girl {
        display: none;
      }
    }
  }
}

@media (max-width: 870px) {
  .heroPricing {
    .h4_container {
      .girl {
        bottom: -150px;
        left: 300px;
        transform: none;
      }
    }
  }
}

@media (max-width: 810px) {
  .heroPricing {
    .h4_container {
      .pricingImageContainer .herobg {
        right: 50%;
        transform: translateX(50%);
      }
      .shape4 {
        top: 29%;
        right: -14%;
        bottom: none;
        width: 317px;
        opacity: 0.1;
      }
      .shape5 {
        bottom: 38%;
        left: 22%;
      }
      .shapeOutline {
        top: 23%;
        right: 10%;
        bottom: none;
      }
      .girl {
        bottom: -0px;
        left: 50px;
      }
      .pricingPlan {
        align-items: center;
        flex-direction: column;
        flex: 1;

        // .pricingPlanLite {
        //   margin: 0px 200px 50px 0px;
        // }
        // .pricingPlanPro {
        //   margin: 0px 0px 50px 200px;
        // }
      }
    }
  }
  .heroPricingClients {
    .clientsContainer {
      border: none;

      .clients {
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 700px) {
  .heroPricing {
    .h4_container {
      .girl {
        bottom: -0px;
        left: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .heroPricing {
    .h4_container {
      .girl {
        display: none;
        bottom: -0px;
        left: 20px;
      }
      .shape5 {
        bottom: 40%;
        left: 5%;
      }
      .shapeOutline {
        right: none;
        bottom: 38%;
        left: 5%;
      }
      .pricingPlan {
        align-items: center;
        flex-direction: column;
        flex: 1;
        .pricingPlanBtnContainer {
          padding: 0 0px 10px;
          width: 100%;
          .h4_btn {
            min-width: 1px;
          }
        }
        .pricingPlanLite {
          margin: 0px 0px 50px 0px;
        }
        .pricingPlanPro {
          margin: 0px 0px 50px 0px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .heroPricingClients {
    .clientsContainer {
      .clients {
        img {
          margin: 0;
        }
      }
    }
  }
}




.pricingDetailsTable {
  width: 100%;
  margin-top: 30px;
  img.pricingCheck {
    width:16px;
    height: 16px;
  }
  .pricingSpecial {
    margin-left: 3px;

    &.unlimited {
      color: green;
    }

    &.comingSoon {
      color: green;
    }
  }

  .flex-table {
    display: grid;
    /*grid-template-columns: repeat(auto-fill, 25%);*/
    grid-template-columns: 40% 20% 20% 20%;
    grid-template-rows: 100% auto;
    border-left: solid 1px #d9d9d9;
    transition: 0.5s;

    // &:first-of-type {
    //   border-top: solid 1px rgb(0, 157, 245);
    //   border-left: solid 1px rgb(0, 157, 245);
    // }

    // &:first-of-type .flex-row {
    //   background: rgb(0, 157, 245);
    //   color: white;
    //   border-color: rgb(0, 157, 245);
    // }

    &.row:nth-child(odd) .flex-row {
      background: #f4f2f1;
    }

    &:hover {
      background: #f5f5f5;
      transition: 500ms;
    }
  }

  .flex-row {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;

    &.first {
      text-align: left;
      font-family: @euclidRegular;
    }
  }
  .flex-table-header {
    border-left-width: 0px;
    .flex-row {
      font-family: @euclidBold;
      border-right-width: 0px;
      text-transform: uppercase;
    }
    .pricingPlanLite {
      color: #009df5;
    }
    .pricingPlanPro {
      color: #83d63f;
    }
    .pricingPlanCustom {
      color: #b83fd6;
    }
  }
  .rowspan {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 100%;
  }

  .column {
    width: 100%;
    padding: 0;

    .flex-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, 33.3%);
      grid-template-rows: 100% 100% 100%;
      width: 100%;
      padding: 0;
      border: 0;
      border-bottom: solid 1px #d9d9d9;

      &:hover {
        background: #f5f5f5;
        transition: 500ms;
      }
    }
  }

  .flex-cell {
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
  }

}
div {
  box-sizing: border-box;
}

.table-container {
  display: block;
  margin: 4em auto;
  /*width: 90%;*/
  max-width: 100%;
  img.feature-status {
    width: 16px;
    height: 16px;
  }
}

//(min-width: 431px) and
@media all and (max-width: 768px) {
  .pricingDetailsTable {
    .flex-table {
      display: grid;
      grid-template-columns: repeat(auto-fill, 33.33%);
      //grid-template-rows: repeat(auto-fill, 100%);
      grid-template-rows: auto;

      .flex-row {
        border-bottom: solid 1px #d9d9d9;
      }
    }

    .rowspan {
      display: grid;
      grid-template-columns: repeat(auto-fill, 100%);
      grid-template-rows: repeat(auto-fill, 100%);

      .flex-row {
        display: grid;
        grid-template-columns: repeat(auto-fill, 33.33%);
        border-bottom: solid 1px #d9d9d9;
      }

      p {
        margin: 0;
      }
    }

    .first {
      grid-column-start: 1;
      grid-column-end: 4;
      display: grid;
    }

    .rowspan>.first {
      display: block;
    }
  }
}

// @media all and (max-width: 430px) {
// .pricingDetailsTable {
//     .flex-table {
//       display: grid;
//       grid-template-columns: repeat(auto-fill, 100%);
//       grid-template-rows: 100% auto;

//       .flex-row {
//         border-bottom: 0;

//         &:last-of-type {
//           border-bottom: solid 1px #d9d9d9;
//         }
//       }
//     }

//     .first {
//       border-bottom: 0;
//       grid-column-start: auto;
//       grid-column-end: auto;
//     }

//     .rowspan {
//       display: grid;
//       grid-template-columns: repeat(auto-fill, 100%);
//       grid-template-rows: 100% auto;

//       .flex-row {
//         display: grid;
//         grid-template-columns: repeat(auto-fill, 100%);
//         grid-template-rows: auto;
//         border-bottom: solid 1px #d9d9d9;
//       }

//       p {
//         margin: 0;
//       }
//     }
//   }
// }
