.elevatoClients {
  padding: 90px 0px;

  & > .h4_container {
    position: relative;
  }

  .clientsImageContainer {
    .herobg {
      display: block;
      position: absolute;
      top: -155%;
      right: -32%;
      z-index: -1;
    }

    .shapeBase {
      position: absolute;
      animation-duration: 6s;
      animation-name: float;
    }

    .shape1 {
      top: 50px;
      right: 185px;
      width: 30px;
      opacity: 0.2;
    }

    .shape2 {
      top: -16px;
      right: -10px;
      left: inherit;
      width: 65px;
      opacity: 0.2;
      animation-duration: 8s;
    }
  }

  .clientsHeader {
    margin-bottom: 80px;

    .mainHeader {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  .clientsGrid {
    display: flex;
    flex-wrap: wrap;

    .clientItem {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      height: 140px;
      width: 20%;
      border: 1px solid rgba(193, 225, 243, 0.75);
      border-top: none;
      border-right: none;
      filter: grayscale(100%);
      transition: 0.3s ease-in-out;

      &:hover {
        filter: grayscale(0%);
      }

      &:nth-of-type(5n - 4) {
        border-left: none;
      }

      &:nth-of-type(n + 11) {
        border-bottom: none;
      }

      .clientItem__image {
        height: auto;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1023px) {
  .elevatoClients {
    overflow: hidden;
    padding: 80px 0px;

    .clientsGrid {
      .clientItem {
        padding: 0px;
        height: 110px;
      }
    }
  }
}
@media (max-width: 800px) {
  .elevatoClients {
    .clientsGrid {
      .clientItem {
        height: 100px;
      }
    }
  }
}

@media (max-width: 630px) {
  .elevatoClients {
    .clientsHeader {
      margin-bottom: 32px;
    }
    .clientsGrid {
      .clientItem {
        width: calc(100% / 3);
        padding: 16px;
        height: 80px;

        &:nth-of-type(5n - 4) {
          border-left: 1px solid rgba(193, 225, 243, 0.75);
        }

        &:nth-of-type(n + 11) {
          border-bottom: 1px solid rgba(193, 225, 243, 0.75);
        }

        &:nth-of-type(3n - 2) {
          border-left: none;
        }

        &:nth-of-type(n + 13) {
          border-bottom: none;
        }

        .clientItem__image {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .elevatoClients {
    .clientsGrid {
      .clientItem {
        padding: 0px;
      }
    }
  }
}
