.about-us {
  padding: 50px 20px 145px;

  .carousel-container {
    position: relative;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }

  .carousel-track-container {
    overflow: hidden;
    width: 100%;
  }

  .carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-slide {
    min-width: 100%;
    text-align: center;
  }

  .carousel-slide img {
    width: 100%;
    border-radius: 10px;
  }

  .carousel-caption {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #333;
  }

  .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10;
    // padding: 10px 15px;
    border-radius: 50%;
    font-size: 1.5rem;
  }

  .carousel-btn.prev {
    left: 10px;
    padding-right: 7px;
  }

  .carousel-btn.next {
    right: 10px;
    padding-left: 10px;
  }

  .carousel-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .mainHeader {
    text-align: center;
    font-size: 50px;
  }

  .mainHeader_2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }

  .mainHeader_3 {
    text-align: center;
    font-size: 50px;
    margin-bottom: 70px;
  }

  .team_image {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    max-width: 90%;
    margin-bottom: 30px;

    img {
      width: 100%;
      max-width: 1400px;
      height: auto;
      border-radius: 8px;
    }
  }

  .description_1 {
    margin: 0 0 50px;
    color: #0f365c;
    opacity: .8;
    font-size: 1.375rem;
    letter-spacing: -.43px;
    text-align: center;
  }

  .description_2 {
    margin: 0 0 50px;
    color: #0f365c;
    opacity: .8;
    font-size: 1.375rem;
    letter-spacing: -.43px;
    text-align: center;
  }

  .description_3 {
    color: #666;
    margin-bottom: 60px;
    font-size: 20px;
    text-align: center;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .top_KO img {
      width: 450px;
      height: 300px;
      object-fit: cover;
      height: 50%;
      border-radius: 8px;
    }
  }

  .description_4 {
    margin: 0 0 50px;
    color: #0f365c;
    opacity: .8;
    font-size: 1.375rem;
    letter-spacing: -.43px;
    text-align: center;
  }

  .description_5 {
    margin: 0 0 50px;
    color: #0f365c;
    opacity: .8;
    font-size: 1.375rem;
    letter-spacing: -.43px;
    text-align: center;
  }

  .description_6 {
    margin-top: 60px;
    font-size: 30px;
    text-align: center;

    .elevato-grow-logo {
      width: 350px;
      max-width: 100%;
      height: auto;
      margin: 60px auto 0px;
      display: block;
    }
  }

  .aboutUsCardsContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0px 35px;
  }

  .aboutUsCard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 40px 40px 8px 40px;
    min-height: 160px;
    width: 100%;
    max-width: 550px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);

    .aboutUsCardHeader {
      .nameAndPhoto {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          font-size: 32px;
          font-weight: bold;
          color: #333;
        }

        .aboutUsLogo {
          img {
            width: 260px;
            height: 260px;
            background-size: cover;
            background-position: center;
          }
        }
      }

      >p {
        margin-top: 10px;
        font-size: 18px;
        color: #666;
      }

      .job_title {
        text-align: center;
        font-size: x-large;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
  }
}

.bg {
  position: absolute;
  top: -100px;
  left: 600px;
  right: 700px;
  width: 800px;
  height: 800px;
  background-image: url('/assets/img/home/top-shape.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 1;
}

.bg2 {
  position: absolute;
  top: 500px;
  left: 1600px;
  right: 400px;
  width: 1000px;
  height: 1000px;
  background-image: url('/assets/img/home/shape.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.1;
}

.bg3 {
  position: absolute;
  top: 500px;
  left: 100px;
  right: 900px;
  width: 500px;
  height: 500px;
  background-image: url('/assets/img/home/shape.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.1;
}



/* ------------- MEDIA QUERIES ------------- */



@media (max-width: 1200px) {
  .about-us {
    padding-top: 80px;

    .aboutUsCardsContainer {
      align-items: center;
      flex-direction: column;
    }
  }
}

@media (max-width: 1023px) {
  .about-us {
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .about-us {
    padding-top: 80px;

    .aboutUsCard {
      padding: 20px;
      max-width: 100%;

      .aboutUsCardHeader {
        .nameAndPhoto {
          // imię i nazwisko nad obrazkiem na mobilnych
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;

          p {
            margin-bottom: 10px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }

          .aboutUsLogo img {
            width: 160px;
            height: 160px;
          }
        }
      }

      .job_title {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .aboutUsCardFooter {
        padding-top: 16px;
      }
    }
  }

  .description_3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;

    .top_KO img {
      width: 90%;
      max-width: 300px;
      height: auto;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

@media (max-width: 630px) {
  .about-us {
    .mainHeader {
      margin-bottom: 50px;
    }

    .aboutUsCard {
      padding: 20px;
      max-width: 100%;

      .aboutUsCardHeader {
        .nameAndPhoto {
          p {
            font-size: 18px;
          }

          .aboutUsLogo img {
            width: 160px;
            height: 160px;
          }
        }

        .job_title {
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &.imageWrapper {
        position: relative;
        width: auto;
        height: auto;
        min-height: 1px;
        padding-top: 0px;

        .girlbg {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 220px;
        }
      }

      .aboutUsCardFooter {
        flex-direction: column;
        align-items: center;

        .aboutUsSignature {
          margin-bottom: 0px;
          text-align: center;
          text-align-last: center;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .about-us {
    .aboutUsCard {
      &.imageWrapper {
        .girlbg {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
        }
      }
    }
  }
}