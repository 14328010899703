.pricingAbout {
  padding: 30px 0px 80px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .pricingAboutTitle {
    color: rgba(15, 54, 92, 1);
    font-size: 2.625rem;
    font-family: @euclidBold;
    letter-spacing: -0.82px;
    line-height: 49px;
    text-align: center;
  }

  .pricingAboutListContainer {
    padding-top: 62px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .pricingLeftList,
  .pricingRightList {
    ul {
      list-style: none;
      padding-left: 30px;
      margin: 0px;
      .pricingListItem {
        padding: 0px 0px 12px;
        color: rgba(15, 54, 92, 1);
        font-family: @euclidRegular;
        font-size: 1.125rem;
        line-height: 24px;
        letter-spacing: -0.35px;
        &:before {
          display: inline-block;
          margin-left: -30px;
          width: 30px;
          color: rgba(11, 157, 246, 1);
          content: '\2022';
          font-size: 2rem;
          position: relative;
          top: 3px;
        }
      }
    }
  }
  .pricingRightList {
    padding-left: 30px;
  }
}

@media (max-width: 800px) {
  .pricingAbout {
    .pricingAboutListContainer {
      flex-direction: column;
    }

    .pricingRightList {
      padding-left: 0px;
    }
  }
}
