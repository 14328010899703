html {
  overflow-x: hidden;
}
address {
  font-style: normal;
}
a {
  color: #0f365c;
  font-family: @euclidRegular;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: @euclidBold;
}
strong {
  font-family: @euclidBold;
}
b {
  font-family: @euclidBold;
}
.h4_container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1184px;
}
.mainHeader {
  margin: 0 0 18px 0px;
  font-family: @euclidBold;
  font-size: 2.625em;
  letter-spacing: -0.82px;
}
.heading {
  margin: 0 0 18px 0px;
  font-family: @euclidBold;
  font-size: 2.125rem;
  letter-spacing: -0.66px;
}

.text {
  max-width: 500px;
  font-family: @euclidRegular;
  font-size: 1.125rem;
  letter-spacing: -0.35px;
}
.textBold {
  max-width: 500px;
  font-family: @euclidBold;
  font-size: 1.125rem;
  letter-spacing: -0.35px;
}
.minorText {
  padding: 0 10px 0 0;
  font-family: @euclidRegular;
  font-size: 0.875em;
  letter-spacing: -0.27px;
}

.textBelow, .textAbove {
  font-size: 1.375rem;
  letter-spacing: -0.43px;
  text-align: center;
}

#scrollToTop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 39px;
  width: 39px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 999;
  visibility: hidden;
}

#scrollToTop.privacyActive {
  bottom: 90px;
}

#scrollToTop.show {
  opacity: 1;
  visibility: visible;
}


@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.90);
  }
}
@keyframes pulseAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.90);
  }
}

.shapeBase {
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-name: pulseAnimation;
  animation-timing-function: ease-in-out;
}

main {
  display: block;
}

@media (max-width: 630px) {
  .mainHeader {
    font-size: 2.125em;
  }

  #scrollToTop {
    bottom: 80px;
    height: 20px;
    width: 20px;
  }
}

.policyContainer {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 0 1em;
  }
}
