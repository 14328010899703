.elevatoNoscript {
  padding: 90px 0px 0px 0px;

  & > .h4_container {
    position: relative;
  }

  .noscriptImageContainer {
    .herobg {
      display: block;
      position: absolute;
      top: -155%;
      right: -32%;
      z-index: -1;
    }

    .shapeBase {
      position: absolute;
      animation-duration: 6s;
      animation-name: float;
    }

    .shape1 {
      top: 50px;
      right: 185px;
      width: 30px;
      opacity: 0.2;
    }

    .shape2 {
      top: -16px;
      right: -10px;
      left: inherit;
      width: 65px;
      opacity: 0.2;
      animation-duration: 8s;
    }
  }

  .noscriptHeader {
    margin-bottom: 0px;

    .mainHeader {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  .noscript-browsers-links {
    margin: 60px auto;
    text-align: center;
    img {
      margin: 20px;
      max-width: 80px;
      display: inline;
      cursor: pointer;
    }
  }

  .img-responsive {
    max-width: 100%;
  }
}

.noscript {
  padding: 60px 20px;

  .img-responsive {
    max-width: 100%;
  }

  .browser-section {
    margin-bottom: 80px;
    .img-browser {
      max-width: 80px;
      float: left;
      margin-right: 40px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1023px) {
  .elevatoNoscript {
    overflow: hidden;
    padding: 80px 0px;
  }
}

@media (max-width: 630px) {
  .elevatoNoscript {
    .noscriptHeader {
      margin-bottom: 32px;
    }
  }
}
