.testimonials {
  padding-top: 140px;
  padding-bottom: 145px;

  .mainHeader {
    margin-bottom: 70px;
    text-align: center;
  }

  .testimonialsCardsContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .testimonialCard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 40px 40px 8px 40px;
    min-height: 380px;
    width: 100%;
    max-width: 550px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);
    > div > p {
      margin: 0 0 12px 0;
      &:last-of-type {
        margin: 0;
      }
    }

    &.imageWrapper {
      position: relative;
      background-color: transparent;
      border-radius: 0px;
      box-shadow: none;

      .girlbg,
      .iconBase,
      .shapeBase {
        position: absolute;
        height: auto;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        z-index: -1;
      }

      .girlbg {
        bottom: -48px;
        left: 102px;
        width: 335px;
      }
      .icon1 {
        top: 2px;
        left: -9px;
        width: 203px;
      }
      .icon2 {
        top: -62px;
        left: 330px;
        width: 247px;
      }
      .icon3 {
        top: -46px;
        left: 398px;
        width: 34px;
        animation-duration: 4s;
        animation-name: pulseAnimation1;
      }
      .icon4 {
        top: -78px;
        left: 440px;
        width: 25px;
        animation-duration: 6s;
        animation-name: pulseAnimation2;
      }
      .icon5 {
        top: -48px;
        left: 472px;
        width: 41px;
        animation-duration: 5s;
        animation-name: pulseAnimation3;
      }
    }

    &:nth-of-type(2n + 1) {
      position: relative;
      top: 50px;
      margin-right: 16px;
      margin-left: 4px;
    }

    &:nth-of-type(2n) {
      margin-left: 16px;
      margin-right: 4px;
    }

    .testimonialCardFooter {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: nowrap;

      .testimonialSignature {
        display: flex;
        margin-bottom: 32px;
        .testimonialSignatureText {
          color: #0f365c;
          opacity: 0.5;
          font-size: 18px;
          font-style: italic;
          letter-spacing: -0.35px;
        }
      }
    }
  }
}

@keyframes pulseAnimation1 {
  0%,
  100% {
    transform: scale(1) rotate(-26deg);
  }
  50% {
    transform: scale(0.9) rotate(-26deg);
  }
}

@keyframes pulseAnimation2 {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}
@keyframes pulseAnimation3 {
  0%,
  100% {
    transform: scale(1) rotate(31deg);
  }
  50% {
    transform: scale(0.9) rotate(31deg);
  }
}

@media (max-width: 1200px) {
  .testimonials {
    padding-top: 80px;

    .testimonialsCardsContainer {
      align-items: center;
      flex-direction: column;
    }

    .testimonialCard {
      &:nth-of-type(2n + 1) {
        position: relative;
        top: 0px;
        margin-left: 100px;
        align-self: center;
        margin-right: 0px;
      }

      &:nth-of-type(2n) {
        margin-right: 100px;
        margin-left: 0px;
        align-self: center;
      }

      &:nth-of-type(1) {
        margin-right: 0px;
        align-self: center;
        margin-right: 0px;
      }
    }
  }
}
@media (max-width: 1023px) {
  .testimonials,
  .testElevato {
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .testimonials {
    padding-top: 80px;

    .testimonialCard {
      &:nth-of-type(2n + 1) {
        position: relative;
        margin-left: 0px;
        top: 0px;
        margin-right: 0px;
      }

      &:nth-of-type(2n) {
        margin-left: 0px;
        margin-right: 0px;
      }

      &:nth-of-type(1) {
        margin-right: 0px;
      }

      .testimonialCardFooter {
        padding-top: 16px;
      }
    }
  }
}

@media (max-width: 630px) {
  .testimonials {
    .mainHeader {
      margin-bottom: 0px;
    }

    .testimonialCard {
      &.imageWrapper {
        position: relative;
        width: auto;
        height: auto;
        min-height: 1px;
        padding-top: 0px;

        .girlbg {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 220px;
        }
        .icon1 {
          top: 25%;
          left: -8%;
          width: 150px;
        }
        .icon2 {
          top: 7%;
          left: 59%;
          width: 190px;
        }
        .icon3 {
          top: 10%;
          left: 72%;
        }
        .icon4 {
          top: 3%;
          left: 85%;
        }
        .icon5 {
          top: 8%;
          left: 96%;
        }
      }

      .testimonialCardFooter {
        flex-direction: column;
        align-items: center;
        .testimonialSignature {
          margin-bottom: 0px;
          text-align: center;
          text-align-last: center;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .testimonials {
    .testimonialCard {
      &.imageWrapper {
        .girlbg {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
        }
        .icon1 {
          top: 25%;
          left: -8%;
          width: 120px;
        }
        .icon2 {
          top: 7%;
          left: 59%;
          width: 150px;
        }
        .icon3 {
          top: 10%;
          left: 72%;
          width: 25px;
        }
        .icon4 {
          top: 3%;
          left: 85%;
          width: 19px;
        }
        .icon5 {
          top: 8%;
          left: 96%;
          width: 30px;
        }
      }
    }
  }
}
