@font-face {
  font-family: 'Euclid-Bold';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/Euclid/EuclidSquare-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Euclid-SemiBold';
  font-style: bold;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/Euclid/EuclidSquare-Semibold.otf) format('opentype');
}
@font-face {
  font-family: 'Euclid-Medium';
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/Euclid/EuclidSquare-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Euclid-Italic';
  font-weight: 400;
  font-display: swap;
  src: url(../../fonts/Euclid/EuclidSquare-RegularItalic.otf) format('opentype');
}
@font-face {
  font-family: 'Euclid-Regular';
  font-weight: 400;
  font-display: swap;
  src: url(../../fonts/Euclid/EuclidSquare-Regular.otf) format('opentype');
}

@euclidBolder: 'Euclid-Bold', 'Arial';
@euclidBold: 'Euclid-SemiBold', 'Arial';
@euclid: 'Euclid-Medium', 'Arial';
@euclidRegular: 'Euclid-Regular', 'Arial';
@euclidItalic: 'Euclid-Italic', 'Arial';
