.pricingFaq {
  margin-bottom: 122px;
  .pricingFaqTitle {
    margin-bottom: 73px;
    color: rgba(15, 54, 92, 1);
    font-family: @euclidBold;
    font-size: 2.625rem;
    font-weight: bold;
    line-height: 49px;
    letter-spacing: -0.82px;
    text-align: center;
  }
}

.faqContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(223, 240, 255, 1);
  border-radius: 12px;
  box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);
}

.faqAccordionItem {
  width: 100%;
  border-bottom: 1px solid rgba(36, 147, 255, 0.15);

  &:last-of-type {
    border-bottom: none;
  }
}

.faqAccordionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 30px 50px;
  cursor: pointer;

  .faqAccordionTitleText {
    color: rgba(15, 54, 92, 1);
    font-family: @euclidBold;
    font-size: 1.375rem;
    font-weight: bold;
    letter-spacing: -0.43px;
    transition: 0.3s ease-in-out;
  }
  .faqAccordionTitleIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 10px;
    margin: 10px;
    min-height: 32px;
    max-height: 32px;
    min-width: 32px;
    max-width: 32px;
    background-color: rgba(219, 238, 248, 1);
    border-radius: 50%;
    transition: 0.3s ease-in-out;

    svg {
      position: relative;
      height: 9px;
      width: auto;
      color: #009df5;
      transition: 0.3s ease-in-out;
      top: 0px;
    }
  }
}

.faqAccordionContent {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  visibility: hidden;

  .faqAccordionContentInner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px 50px;

    .faqAccordionLeftContent,
    .faqAccordionRightContent {
      width: 100%;

      .faqAccordionContentTitle {
        margin-bottom: 16px;
        color: rgba(15, 54, 92, 1);
        font-family: @euclidBold;
        font-size: 1.125rem;
        font-weight: bold;
        letter-spacing: -0.35px;
        transition: 0.3s ease-in-out;
      }
      .faqAccordionContentText {
        margin-bottom: 46px;
        color: rgba(15, 54, 92, 1);
        font-family: @euclidRegular;
        font-size: 1.125rem;
        letter-spacing: -0.35px;
      }
      a {
        text-decoration: underline;
      }
    }
    .faqAccordionLeftContent {
      padding-right: 25px;
    }
    .faqAccordionRightContent {
      padding-left: 25px;
    }
  }
}

.faqAccordionItem.active {
  .faqAccordionTitle {
    .faqAccordionTitleText {
      color: rgba(15, 54, 92, 0.5);
    }
    .faqAccordionTitleIcon {
      background-color: rgba(137, 156, 175, 0.5);
      transition: 0.3s ease-in-out;
      svg {
        transform: rotateX(180deg);
        color: #fff;
        top: -1px;
      }
    }
  }
}

@media (max-width: 855px) {
  .faqAccordionContent {
    .faqAccordionContentInner {
      flex-direction: column;
      .faqAccordionLeftContent {
        padding-right: 0px;
      }
      .faqAccordionRightContent {
        padding-left: 0px;
      }
    }
  }
}

@media (max-width: 630px) {
  .faqAccordionContent {
    .faqAccordionContentInner {
      padding: 20px 20px;
    }
  }
  .faqAccordionTitle {
    padding: 20px 20px;
  }
}
