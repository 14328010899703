.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 30px;
}

.carousel-track-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.carousel-slide {
  flex: 0 0 120%;
  max-width: 100%;
  padding: 40px 40px 40px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-height: auto;
}

.testimonialCardHeader {
  flex-grow: 1;
}

.testimonialCardHeader p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #0f365c;
  text-align: left;
}

.testimonialCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 15px;
  margin-top: 15px;
  white-space: nowrap;
  width: 100%;
}

.testimonialSignatureText {
  font-style: italic;
  font-size: 14px;
  margin: 0;
  color: #0f365c;
  flex-grow: 1;
  text-align: left;
  white-space: nowrap;
}

.carouselImage {
  max-height: auto;
  min-height: auto;
  min-width: 200px;
  opacity: 0.8;
  flex-shrink: 0;
  margin-left: auto;
  width: auto;
  height: auto;
  max-width: 100px;
}

.carousel-btn {
  position: absolute;
  top: calc(45% - 1px);
  transform: translateY(-50%);
  background-color: rgba(27, 17, 17, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
  outline: none;
}

.carousel-btn:active,
.carousel-btn:focus {
  background-color: rgba(27, 17, 17, 0.5);
  box-shadow: none;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(230, 227, 226, 0.164);
  transform: translateY(-50%) scale(1.1);
}

@media (max-width: 768px) {
  .carousel-container {
    max-width: 100%;
    padding: 0 25px;
  }

  .carousel-slide {
    padding: 35px 25px 15px;
    min-height: auto;
  }

  .testimonialCardHeader p {
    font-size: 15px;
  }

  .testimonialCardFooter {
    flex-direction: column;
    align-items: flex-start;
  }

  .testimonialSignatureText {
    font-size: 13px;
  }

  .carouselImage {
    max-width: 90px;
  }


}

@media (max-width: 480px) {
  .carousel-container {
    max-width: 100%;
    width: 100%;
    padding: 0;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .carousel-track {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .carousel-slide {
    padding: 25px 20px 15px;
    min-height: auto;
    scroll-snap-align: start;
  }

  .testimonialCardHeader p {
    font-size: 14px;
  }

  .testimonialSignatureText {
    font-size: 13px;
    text-align: center;
    width: 100%;
    white-space: wrap;
  }

  .carouselImage {
    max-width: 100px;
    align-items: center;
    justify-content: center;
  }

  .carousel-btn {
    display: none;
  }
}