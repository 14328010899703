.colaboration {
  margin-top: 80px;
  margin-bottom: 60px;

  & .h4_container {
    position: relative;
    padding: 0 20px 20px 20px;

    .shapeBase {
      position: absolute;
    }
    .shape1 {
      top: 72px;
      left: -24px;
      width: 345px;
      animation-duration: 5s;
    }
    .mainbg {
      display: block;
      position: absolute;
      top: 21%;
      left: 56%;
      width: 89%;
      z-index: -1;
    }
  }

  .mainHeader {
    margin-bottom: 32px;
    text-align: center;
  }

  .cardContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;

    .card {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 24px 32px 32px;
      min-height: 305px;
      width: 100%;
      max-width: 355px;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);

      &:first-of-type {
        position: relative;
        left: -10px;
      }

      &:nth-of-type(2) {
        position: relative;
        top: 50px;
      }

      &:last-of-type {
        position: relative;
        left: 10px;
      }

      .cardHeader {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .cardHeaderImage {
          display: block;
          margin-right: 32px;
          width: 38px;
          min-height: 36px;
          max-height: 36px;
        }

        .cardHeaderRightImage {
          display: block;
          margin-left: 14px;
          width: 64px;
        }

        .cardHeader__title {
          font-size: 20px;
          font-family: @euclidBold;
          letter-spacing: -0.39px;
        }
      }

      .cardFooter {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        .cardFooterImage {
          width: 50%;
          height: 50px;
        }
      }

      .horizontalLine {
        margin: 24px 0px;
        height: 1px;
        width: 51px;
        border: 1px solid #cdecfd;
        box-sizing: border-box;
      }
    }
    & .wide {
      max-width: unset;
    }
  }
}

@media (max-width: 1023px) {
  .colaboration {
    overflow: hidden;
  }

  .colaboration .h4_container {
    position: relative;
    padding: 0 20px 80px 20px;
  }
}
@media (max-width: 900px) {
  .colaboration {
    .h4_container {
      position: relative;
      padding: 0 20px 30px 20px;
    }

    .cardContainer {
      flex-direction: column;
      align-items: center;
      .card {
        &:first-of-type {
          left: 0px;
          margin-bottom: 24px;
          margin-right: 100px;
        }

        &:nth-of-type(2) {
          top: 0px;
          margin-bottom: 24px;
          margin-left: 100px;
        }

        &:last-of-type {
          left: 0px;
          margin-right: 100px;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .colaboration {
    margin-bottom: 0px;
    padding: 0 0 20px 0;
    .cardContainer {
      margin-top: 32px;
      .card {
        &:first-of-type {
          margin-right: 0px;
        }

        &:nth-of-type(2) {
          margin-left: 0px;
        }

        &:last-of-type {
          margin-right: 0px;
        }

        .cardHeader {
          .cardHeaderImage {
            margin-right: 16px;
            min-height: 30px;
            max-height: 30px;
          }
          .cardHeader__title {
            font-size: 16px;
          }
        }

        .cardFooter {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          height: auto;
          .cardFooterImage {
            width: 50%;
            height: auto;
          }
        }
      }
    }
  }
}
