.testElevato {
  padding: 0px 0 150px 0;
  .mainHeader {
    text-align: center;
    margin: -30px 0 10px 0;
  }
  .textBelow {
    margin: 0 0 60px 0;
  }
  .buttonCTA {
    margin: 0 auto;
  }
}

.testElevatoLogo {
  display: block;
  margin: 0 auto;
  width: 195px;
}

.testBottomContainer {
  display: flex;
  justify-content: space-between;
  padding: 230px 0 0 0;
  margin: 0 auto;
  max-width: 1135px;
  width:100%;
  position: relative;
  .shapeBG {
    position: absolute;
    right: -54%;
    top: -150px;
    transform: scaleX(-1) scaleY(-1);
    z-index: -2;
  }
  .heading {
    max-width: 400px;
  }
}
.testText {
  padding: 50px 20px 0 20px;
}


.testImages {
  display: flex;
  justify-content: space-between;
  .testItem {
    width: 200px;
    height: 263px;
    padding: 0 20px 30px 20px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 25px 0 rgba(147,200,222,0.37);  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .textImg {
      position: absolute;
      width: 155px;
      left: 0;
      right: 0;
      display: block;
      margin: 0 auto;
      top: -25px;
    }
    &.testGirlItem {
      margin: 54px 31px 0 31px;
      .textImg {
        top: -73px;
        width: 140px;
      }
    }
    p {
      font-size: 42px;
      font-family: @euclidBold;
      letter-spacing: -0.82px;
      text-align: center;
      margin: 0 0 9px 0;
    }
    h5 {
      font-size: 14px;
      letter-spacing: -0.27px;
      text-align: center;
    }
  }
}

.testBottomContainer {
  .shapeBase {
    position: absolute;
    z-index: -1;
    animation-duration: 4s;
    animation-name: pulseAnimation;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  .shape1{
    top: -7px;
    right: 31px;
    width: 16px;
    z-index: 1;
  }
  .shape2{
    top: -108px;
    left: 18px;
  }
  .shape3{
    bottom: -150px;
    left: 48%;
  }
  .shape4{
    bottom: -109px;
    left: 10px;
    width: 60px;
    opacity: 0.4;
  }
  .shape5 {
    right: -43px;
    top: 77px;
    width: 60px;
  }
  .shape6 {
    top: -84px;
    right: 170px;
    width: 120px;
    opacity: 0.2;
  }
  .shapeOutline {
    position: absolute;
    top: -110px;
    right: -110%;
    z-index: -1;
  }
}


@media (max-width: 1100px){
  .testImages {
    .testItem {
      width: 175px;
      height: 230px;
      top: -45px;
      .testImg {
        width: 125px;
      }
      p {
        font-size: 32px;
      }
      &.testGirlItem {
        .textImg {
          width: 110px;
        }
      }
    }
  }
}

@media (max-width: 1023px){
.testBottomContainer {
  flex-direction: column;
  padding: 120px 0 0 0;
  .shapeBG {
    right: -45%;
    top: 0;
  }
  .shape6 {
    top:0;
  }
}
.testImages {
  .testItem {
    &:first-of-type {
      .textImg {
        top: -50px;
      }
    }
  }
}
.testText {
  padding: 0 20px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  .heading {
    margin: 0 auto 16px auto;
  }
}
.testImages {
  padding: 140px 0 0 0;
  max-width: 600px;
  margin: 0 auto;
}
}

@media (max-width: 630px){
  .testElevato {
    padding: 0 0 40px 0;
    .textBelow {
      margin: 0 0 30px 0;
    }
  }
  .testBottomContainer {
    overflow: hidden;
    padding: 120px 0 90px 0;
  }
.testImages {
  flex-wrap: wrap;
  width: 100%;
  max-width: 410px;
  padding:140px 20px 0 20px;
  position: relative;
  .testItem {
    top: 0;
    height: 200px;
    .textImg {
      width: 135px;
    }
    &.testGirlItem {
      margin:0 0 0 20px;
      top: 125px;
    }
    &:last-of-type {
      top: 60px;
    }
  }
}
.testBottomContainer {
  .shapeOutline {
    right: 0;
    left: 0;
    top: -270px;
  }
}
}

@media (max-width: 430px){
  .testImages {
    .testItem {
      width: 145px;
      p {
        font-size: 28px;
      }
    }
  }
}
@media (max-width: 360px){
  .testImages {
    padding: 90px 20px 0 20px;
    .testItem {
      width: 140px;
      left: -10px;
      p {
        font-size: 26px;
      }
      &.testGirlItem {
        margin:0;
        left: initial;
        right: -10px;
      }
    }
  }   
}