.homeBlog {
  padding: 190px 0 135px 0;
  .h4_container {
    position: relative;
  }
  .shapeBase {
    position: absolute;
    z-index: -1;
  }
  .shape1 {
    top: -90px;
    left: 190px;
    width: 120px;
    opacity: 0.2;
    animation-duration: 9s;
  }
  .shape2 {
    top: 24px;
    left: 112px;
    animation-duration: 6s;
  }

  .shapeBG {
    position: absolute;
    top: -110px;
    left: -32%;
    transform: scaleX(-1) scaleY(-1);
    width: 800px;
    z-index: -2;
  }
  .mainHeader {
    margin: 0 0 58px 0px;
    text-align: center;
  }
  .buttonLight {
    margin: 68px auto 0 auto;
    max-width: 225px;
  }
  .blogItemWrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
  }
  .blogItem {
    width: calc((100% - 88px) / 3);
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 11px 38px 0 rgba(181, 202, 210, 0.37);
    h4 {
      padding: 0 30px;
      font-family: @euclidBold;
      font-size: 20px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: -0.39px;
    }
    .minorText {
      padding: 0 30px 30px 30px;
    }
    &:hover {
      .blogSeparator__staticLine {
        opacity: 0;
      }
      .blogSeparator__dynamicLine div {
        transform: scaleX(1);
        transform-origin: 0 50%;
      }
    }
  }
  .blogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px 16px 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.27px;

    .tag {
      color: #00baff;
    }
    .date {
      opacity: 0.3;
      font-size: 14px;
      display: none;
    }
  }
  .blogItemImage {
    position: relative;
    font-size: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        155.82deg,
        rgba(124, 208, 255, 0.36) 0%,
        rgba(0, 157, 245, 0.81) 100%
      );
      content: '';
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
  .blogSeparator {
    margin: 20px 0 20px 30px;
    height: 1px;
    margin: 20px 30px;
    .blogSeparator__staticLine {
      height: 2px;
      width: 51px;
      background-color: rgba(205, 236, 253, 0.8);
      opacity: 1;
      transition: 0.5s ease-in-out;
    }
    .blogSeparator__dynamicLine {
      height: 2px;
      position: relative;
      div {
        position: absolute;
        display: block;
        transform: scaleX(0);
        bottom: 3px;
        left: 0;
        background: rgba(0, 155, 245);
        width: 100%;
        content: '';
        height: 2px;
        transition: transform 250ms ease-in-out;
      }
    }
  }
}

@media (max-width: 1100px) {
  .homeBlog {
    overflow: hidden;
  }
}

@media (max-width: 1050px) {
  .homeBlog {
    .blogItem {
      width: calc((100% - 40px) / 3);
    }
  }
}

@media (max-width: 1023px) {
  .homeBlog {
    .blogItemImage {
      min-width: 300px;
      max-width: 300px;
      border-bottom-left-radius: 12px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:after {
        border-bottom-left-radius: 12px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      img {
        height: 100%;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .blogItemWrap {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
    .blogItem {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      margin: 0 0 20px 0;
      width: 100%;
      a {
        display: flex;
      }
      h4 {
        padding: 0 20px;
      }
      .minorText {
        padding: 10px 20px 20px 20px;
      }
    }
    .blogHeader {
      padding: 20px 20px 10px 20px;
    }
    .blogSeparator {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .homeBlog .blogItemImage {
    min-width: 200px;
    max-width: 200px;
  }
}
@media (max-width: 680px) {
  .homeBlog {
    padding: 120px 0 0 0;
    .shape1 {
      top: 30px;
      left: -62px;
      opacity: 0.2;
    }
    .shape2 {
      top: -60px;
      right: 60px;
      left: initial;
    }
    .blogItemImage {
      min-width: 150px;
      max-width: 150px;
    }
    .blogItem {
      h4 {
        font-size: 18px;
      }
      .minorText {
        font-size: 14px;
      }
    }
    .buttonLight {
      margin: 30px auto 0 auto;
    }
  }
}
@media (max-width: 500px) {
  .homeBlog .blogItemImage {
    display: none;
  }
}
