.headerOuter {
  position: relative;
  z-index: 11;
}

.header {
  transition: 256ms all;
  z-index: 13;
  .h4_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    &.mobile {
      display: none;
    }
  }
  .demoWrap {
    z-index: 1;
    margin: 0 0 0 37px;
  }
}

.h4_sticky {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 15px 18px 0 rgba(57, 81, 94, 0.09);

    .topMenuButton {
      background-color: #83d63f;
      color: #fff;
      svg {
        color: #fff;
      }
      &:hover {
        background: #6aba28;
        color: #fff;
        svg {
          color: #fff;
        }
      }
    }
  }
  & + section {
    margin-top: 100px;
  }
  & + div {
    margin-top: 100px;
  }
}

.doubleStick {
  .h4_sticky {
    header {
      box-shadow: none;
    }
  }
}

.logo {
  width: 194px;
  position: relative;
  top: 4px;
}

.h4_navbar {
  margin: 0 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.navItem {
  font-size: 1rem;
  letter-spacing: -0.31px;
  padding: 41px 24px;
  text-decoration: none;
  color: inherit;
  position: relative;
  outline-color: #009df5;
  transition: 256ms all;
  display: block;
  &:before {
    position: absolute;
    display: block;
    transform: scaleX(0);
    bottom: 20px;
    left: 0;
    background: #009df5;
    width: 100%;
    content: '';
    height: 4px;
    transition: transform 0.25s ease-in-out;
  }
  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: 0;
    }
  }
  &.activeNav {
    color: #009df5;
    &:before {
      transform: scaleX(1);
      transform-origin: 0;
      bottom: 20px;
    }
  }
}

.h4_sticky {
  .navNestedParent {
    .navItem {
      &:before {
        bottom: 0;
      }
    }
  }
}

.nestedNav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 103px;
  left: 0;
  right: 0;
  background: #fff;
  margin: 0;
  height: 76px;
  list-style-type: none;
  opacity: 0;
  transition: 256ms all;
  border-top: 1px solid rgba(201, 218, 227, 0.58);
  box-shadow: 0 15px 18px 0 rgba(57, 81, 94, 0.09);
  pointer-events: none;
  .navItem {
    padding: 27px 24px;
    &:before {
      bottom: 0;
    }
  }
}

.navNestedParent {
  position: relative;
  &:hover {
    .nestedNav {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.headerOuter.hovered {
  .navNestedParent {
    .navItem {
      &.activeNav:before {
        bottom: 0;
      }
      &:before {
        bottom: 0;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: #fff;
    width: 100%;
    height: 100%;
  }
}

.hamburgerWraper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 3px solid #fff;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &.open {
    .hamburger {
      transform: translateX(-50px);
      background: transparent;
      box-shadow: none;
      &:before {
        transform: translate(50px) rotate(45deg);
      }
      &:after {
        transform: translate(50px) rotate(-45deg);
      }
    }
  }
}
.hamburgerMenu {
  display: none;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
  background: #fff;
  flex-direction: column;
  .h4_navbar {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0 0 30px 0;
    text-align: center;
  }
}

.hamburgerWraper.open + .hamburgerMenu {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  transition: 256ms all;
  display: block;
  z-index: 12;
  &.visible {
    visibility: visible;
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
  }
}

.hamburger {
  width: 30px;
  height: 2px;
  background: #000;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: #000;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
  &:before {
    transform: translateY(-10px);
  }
  &:after {
    transform: translateY(10px);
  }
}

@media (max-width: 1100px) {
  .header {
    .demoWrap {
      margin: 0 0 0 18px;
    }
  }

  .navItem {
    padding: 12px 16px;
    &:before {
      content: none;
    }
  }
}

@media (max-width: 1023px) {
  .headerOuter {
    z-index: 20;
  }
  body {
    padding-top: 64px;
  }
  .h4_sticky {
    & + section {
      margin-top: 0px;
    }
    & + div {
      margin-top: 0px;
    }
  }
  .doubleStick {
    .h4_sticky {
      header {
        box-shadow: 0 15px 18px 0 rgba(57, 81, 94, 0.09);
      }
    }
  }

  .headerOuter {
    padding: 0;
    top: 0;
    width: 100%;
    background: #fff;
  }
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 15px 18px 0 rgba(57, 81, 94, 0.09);
    .h4_container {
      &.desktop {
        display: none;
      }
      &.mobile {
        padding: 10px 20px 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: initial;
        .logo {
          width: 130px;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .logo {
    width: 115px;
  }
  .header {
    .h4_container {
      padding: 10px 20px 10px 20px;
    }
  }
  .hamburgerMenu {
    .h4_navbar {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .navItem {
        padding: 14px 24px;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
}
