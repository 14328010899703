.socialWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.socialButton {
  margin: 0 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  &.fb {
    background: #0a7ff9;
    &:before {
      background: #0a7ff9;
    }
  }
  &.ln {
    background: #029df5;
    &:before {
      background: #029df5;
    }
  }
  &.mail {
    background: #0084B4;
    &:before {
      background: #0084B4;
    }
  }
  &.tt {
    background:#08a0e9;
    &:before {
      background:#08a0e9;
    }
  }
  img {
    width: 16px;
  }
  &:before {
    content: '';
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    border-radius: 50%;
    transition: 256ms all;
    z-index: -1;
  }
  &:hover {
    &:before {
      top:-5px;
      left:-5px;
      bottom:-5px;
      right:-5px;
    }
  }
}