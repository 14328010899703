.functionh4_container {
  position: relative;
  .leftRightSection {
    &:first-of-type {
      margin-top: 150px;
    }
  }
  .shape2 {
    position: absolute;
    left: 47%;
    top: -100px;
    width: 27px;
  }
}
.verticalItemRight,
.verticalItemLeft,
.horizontalItemLeft,
.horizontalItemRight {
  position: relative;
}

.verticalItemRight {
  margin-bottom: 0px;
  .baseImg {
    width: 700px;
  }
  .baseImgWrap {
    position: relative;
    top: -115px;
    left: -24px;
  }
  .shape1 {
    position: absolute;
    top: -260px;
    left: -50%;
    transform: scaleX(-1) scaleY(-1);
    opacity: 0.3;
    z-index: -1;
  }
  .floatingItem1 {
    top: 160px;
    left: 41px;
  }
  .floatingItem2 {
    top: 63px;
    right: 30px;
  }
  .floatingItem3 {
    top: 430px;
    left: 214px;
  }
}
.verticalItemLeft {
  margin-bottom: 0px;
  .baseImg {
    width: 700px;
  }
  .baseImgWrap {
    position: relative;
    top: -115px;
    left: -170px;
  }
  .shape1 {
    position: absolute;
    top: -260px;
    left: -50%;
    transform: scaleX(-1) scaleY(-1);
    opacity: 0.3;
    z-index: -1;
  }
  .floatingItem1 {
    top: 160px;
    left: 41px;
  }
  .floatingItem2 {
    top: 63px;
    right: 30px;
  }
  .floatingItem3 {
    top: 430px;
    left: 214px;
  }
}

.horizontalItemLeft {
  margin-bottom: 140px;
  .baseImg {
    width: 650px;
  }
  .baseImgWrap {
    position: relative;
    top: 20px;
    right: 184px;
  }
  .floatingItem1 {
    position: absolute;
    top: -30px;
    right: -47px;
  }
  .floatingItem2 {
    position: absolute;
    bottom: 46px;
    right: 224px;
  }
  .shape1 {
    position: absolute;
    right: 149px;
    width: 110px;
    top: -60px;
    z-index: -1;
    opacity: 0.2;
  }
  .shape2 {
    position: absolute;
    left: 47%;
    top: -100px;
    width: 27px;
  }
}
.horizontalItemRight {
  margin-bottom: 140px;
  .baseImg {
    width: 650px;
  }
  .baseImgWrap {
    position: relative;
    top: 20px;
    right: -70px;
  }
  .floatingItem1 {
    position: absolute;
    top: -30px;
    right: -47px;
  }
  .floatingItem2 {
    position: absolute;
    bottom: 46px;
    right: 224px;
  }
  .floatingItem3 {
    position: absolute;
    top: 6px;
    left: 60px;
  }
  .shape1 {
    position: absolute;
    right: 149px;
    width: 110px;
    top: -60px;
    z-index: -1;
    opacity: 0.2;
  }
  .shape2 {
    position: absolute;
    left: 47%;
    top: -100px;
    width: 27px;
  }
}

@media (max-width: 1500px) {
  .horizontalItemRight {
    .baseImgWrap {
      right: 0px;
    }
    .floatingItem1 {
      right: 60px;
    }
  }

  .verticalItemLeft {
    .floatingItem1 {
      top: 360px;
      left: 460px;
    }
  }
}
@media (max-width: 1100px) {
  .horizontalItemLeft {
    .floatingItem2 {
      position: absolute;
      bottom: 46px;
      right: 150px;
    }
  }
}

@media (max-width: 1023px) {
  .functionh4_container {
    .leftRightSection {
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
  .verticalItemRight,
  .verticalItemLeft,
  .horizontalItemLeft,
  .horizontalItemRight {
    .baseImgWrap {
      position: static;
      margin: 0;
    }
    .baseImg {
      width: 500px;
    }
    .textSection {
      position: relative;
      z-index: 2;
      .heading {
        max-width: 500px;
      }
    }
  }
  .verticalItemRight,
  .horizontalItemRight {
    flex-direction: column;
    margin-bottom: 100px;
  }
  .verticalItemLeft,
  .horizontalItemLeft {
    flex-direction: column-reverse;
    margin-bottom: 100px;
  }

  .verticalItemLeft {
    .floatingItem1 {
      top: 360px;
      left: 41px;
    }
  }
}

@media (max-width: 630px) {
  .verticalItemRight,
  .verticalItemLeft,
  .horizontalItemRight,
  .horizontalItemLeft {
    .textSection {
      margin: 0 0 30px 0;
    }
    .baseImg {
      width: 100%;
      max-width: 500px;
    }
    .floatingItem1 {
      left: 0;
      top: 0;
    }
    .floatingItem2 {
      right: 0;
    }
    .floatingItem3 {
      right: initial;
      left: 0;
      top: 150px;
    }
  }
}
