.webinarsList {
  padding-top: 0px;
  padding-bottom: 40px;

  &.ondemand .postDate.time {
    display: none;
  }

  .mainHeader {
    margin-bottom: 70px;
    text-align: center;
  }

}

.webinarContainer .logo {
  width: 194px;
}

.webinarTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 100px;

  .webinarLogoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 550px;

    &.multiLogoContainer {
      justify-content: space-between;
    }

    .logoContainer:nth-of-type(n + 2) {
      margin-left: 16px;
    }

    .logo {
      margin-bottom: 32px;
    }

    .isecureLogo {
      position: relative;
      top: -10px;
    }

    .shareHireLogo {
      position: relative;
      top: 4px;
    }

    .smallerLogo {
      position: relative;
      max-width: 130px;
    }
  }

  .textBelow {
    margin-top: 8px;
    font-size: 1.5rem;
    text-align: center;
  }

  .mainHeader {
    margin-top: 32px;
    margin-bottom: 0px;
    text-align: center;
  }

  .mainHeader span {
    font-size: 1.5rem;
  }

  .webinarSecondTitle {
    font-size: 1.5rem;
    text-align: center;
  }
}

.webinarInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 66px;

  .squareItem {
    display: flex;
    justify-content: center;
    width: calc(100px / 3);
    min-width: 280px;
  }
}

.speakerName {
  font-size: 1.125rem;
  font-family: @euclidBold;
}

.webinarMainContent {
  position: relative;
  padding: 80px 20px 32px 20px;

  &.webinariumWPS {
    .baseImg {
      margin-top: 80px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 580px;
      left: 0px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 580px;
      left: 605px;
    }
  }

  &.webinariumEXE {
    .webinarSection.leftRightSection .floatingItem1 {
      left: 5px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 530px;
      left: 275px;
    }
  }

  &.webinariumKFE {
    .webinarSection.leftRightSection .floatingItem1 {
      top: 290px;
      left: -25px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 610px;
      top: 320px;
      /*display: none;*/
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 275px;
    }
  }

  &.webinariumROP {
    .baseImg {
      margin-top: 80px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      /*top: 430px;left: 0px;*/
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      /*top: 170px; right: 20px;*/
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      /*top: 580px; left: 605px;*/
      display: none;
    }
  }

  &.webinariumSOU {
    .webinarSection.leftRightSection .floatingItem1 {
      top: 290px;
      left: 0px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 610px;
      top: 320px;
      /*display: none;*/
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 580px;
      left: 275px;
    }
  }

  &.webinariumNWE {
    .webinarSection.leftRightSection .floatingItem3 {
      top: 510px;
      left: 295px;
    }
  }

  &.webinariumSMR_AD2022 {
    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 490px;
      left: 150px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 600px;
      top: 110px;
      /*display: none;*/
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 440px;
      left: 580px;
    }
  }

  &.webinarium_SIM_202209 {
    .webinarSection.leftRightSection {
      padding: 40px 20px 0 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 375px;
      left: -25px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 600px;
      top: 80px;
      /*display: none;*/
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 390px;
      left: 570px;
    }
  }

  &.webinarium_AKA_202211 {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 540px;
      left: 100px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 450px;
      top: 60px;
      /*display: none;*/
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 450px;
      left: 450px;
    }
  }

  &.webinarium_ppp {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 360px;
      left: 40px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 510px;
      top: 325px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 660px;
      left: 90px;
    }
  }

  &.webinarium_thr {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 410px;
      left: 40px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 530px;
      top: 375px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 700px;
      left: 280px;
    }
  }

  &.webinarium_chr {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 235px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 160px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      display: none;
    }
  }

  &.webinarium_cps {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 235px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 214px;
    }
  }

  &.webinarium_jmb {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 235px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 500px;
      left: 280px;
    }
  }

  &.webinarium_cvc {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 235px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 500px;
      left: 280px;
    }
  }

  &.webinarium_rps {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 285px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 280px;
    }
  }

  &.webinarium_oopa {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 200px;
      left: -65px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 635px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 280px;
    }
  }

  &.webinarium_knli {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: 0px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 200px;
      left: -65px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 635px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 280px;
    }
  }

  &.webinarium_staffly {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: 0px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 150px;
      left: -25px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 635px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 280px;
    }
  }

  &.webinarium_atp {

    .newsletterWrapper {
      padding-top: 90px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: 0px;
    }

    .webinarSection.leftRightSection.additionalSection .baseImgWrap .baseImg {
      width: auto;
      max-width: 100%;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: -35px;
      left: 35px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 635px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 530px;
      left: 380px;
    }

    .webinarSection.leftRightSection .textSection ul li {
      margin-bottom: 9px;
      list-style-position: inside;
    }

    .webinarSection.leftRightSection .signup_webinar_newsletter {
      max-width: none;
      width: 80%;
    }
  }

  &.webinarium_nwe2024 {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: 0px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 160px;
      left: -35px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 190px;
      right: 0px;
      left: 610px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 540px;
      left: 280px;
    }
  }

  &.webinarium_grow_premiera {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: 0px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: -35px;
      left: 35px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 635px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 530px;
      left: 280px;
    }

    .webinarSection.leftRightSection .textSection ul li {
      margin-bottom: 9px;
      list-style-position: inside;
    }

  }

  &.webinarium_tpp {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 285px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 560px;
      left: 280px;
    }
  }

  &.webinarium_kke {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 235px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 569px;
      left: 280px;
    }
  }

  &.webinarium_kkj {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 235px;
      left: 1px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      top: 200px;
      right: 0px;
      left: 580px;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 569px;
      left: 280px;
    }
  }

  &.webinarium_PRO_202211 {
    .webinarSection.leftRightSection {
      padding: 90px 20px 90px 20px;
    }

    .webinarSection.leftRightSection .baseImgWrap {
      top: -55px;
    }

    .webinarSection.leftRightSection .floatingItem1 {
      top: 430px;
      left: 0px;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      right: 0px;
      left: 240px;
      top: 30px;
      /*display: none;*/
    }

    .webinarSection.leftRightSection .floatingItem3 {
      top: 505px;
      left: 450px;
    }
  }
}

.webinarShapeWrapper {
  position: absolute;
  top: 200px;
  left: 0;
  transform: rotate(245deg);
  z-index: -1;

  .webinarShape1 {
    position: absolute;
    top: 25px;
    left: -45px;
    opacity: 0.9;
  }

  .webinarShape2 {
    position: absolute;
    bottom: -35px;
    left: -24px;
    width: 40px;
    opacity: 0.5;
  }

  .webinarShape3 {
    position: relative;
    width: 60px;
    opacity: 0.3;
  }
}

.webinarSection {

  .text,
  p:not:floatingtext {
    font-size: 1.125rem;
  }

  .textBold {
    font-size: 1.125rem;
  }

  &.leftRightSection {
    position: relative;
    margin: 0;
    padding: 140px 20px 0 20px;

    .shape1 {
      position: absolute;
      top: -260px;
      left: -50%;
      transform: scaleX(-1) scaleY(-1);
      opacity: 0.3;
      z-index: -1;
    }

    .baseImg {
      width: 700px;
    }

    .baseImgWrap {
      position: relative;
      top: -115px;
      left: -24px;
    }

    .floatingItem1 {
      top: 160px;
      left: 41px;
    }

    .floatingItem2 {
      top: 63px;
      right: 30px;
    }

    .floatingItem3 {
      top: 430px;
      left: 214px;
    }
  }

  &.colaboration {
    margin-top: 0px;

    .cardContainer {
      .card {
        font-family: @euclidRegular;
        min-height: 250px;
      }

      .card:nth-of-type(2) {
        position: relative;
        top: 0px;
      }
    }
  }
}

.webinarTeam {
  position: relative;
  padding-top: 20px;

  .webinarInitLeft {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
    background: linear-gradient(147.41deg,
        #ffffff 0%,
        rgba(206, 237, 255, 0.74) 100%);
    z-index: -1;
  }

  .webinarTeamTitle {
    margin: 30px auto;
    width: 100%;
    max-width: 1184px;
    padding: 0px 20px;

    .heading {
      text-align: center;
    }
  }

  .personIntroduction {
    padding-bottom: 32px;

    .teamImageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .personImage {
        height: auto;
        width: 198px;
        border-radius: 50%;
        overflow: hidden;
      }

      .personIntroductionName {
        margin-top: 8px;
        font-size: 1.5rem;
      }

      .nameTextBelow {
        text-align: center;
        text-align-last: center;
      }
    }

    .teamTextContainer {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      padding: 32px 0px;
    }

    .teamTextContainer>div {
      margin: 0px 16px;
      max-width: 380px;
    }

    a {
      margin: 30px 0 0px 0;
      min-width: 235px;
      max-width: 235px;
    }
  }
}

.webinarFooter {
  .h4_container {
    align-items: flex-start;
    padding: 80px 20px 80px 20px;
  }

  .footerContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .footerItem {
    width: 100%;

    .logo {
      margin-bottom: 32px;
      width: 194px;
    }

    .h4_btn {
      min-width: 360px;
      max-width: 360px;
    }
  }

  .footerText {
    font-size: 1.5rem;
  }
}

@media (max-width: 1023px) {
  .webinarContainer {
    padding: 50px 20px 0 20px;
  }

  .functionContainer {
    padding-top: 80px !important;

    .heading {
      text-align: center;
    }
  }

  .webinarTeam {
    overflow: hidden;
  }

  .webinarSection {
    &.leftRightSection {
      flex-direction: column;
      margin: 80px 0 100px 0;
      padding: 0 20px;

      .shape1 {
        width: 100%;
        left: 0;
      }

      .text,
      .heading {
        margin: 0 auto;
        text-align: center;
      }

      .heading {
        margin-bottom: 18px;
      }

      .baseImg {
        width: 500px;
      }

      .baseImgWrap {
        position: static;
        position: relative;
        top: -50px;
        left: 0px;
        margin: 0;
      }

      .floatingItem3 {
        top: initial;
        bottom: 50px;
      }
    }
  }

  .webinarSection.leftRightSection .textSection {
    padding-right: 0px;
    padding-left: 0px;
  }

  .webinarSection.leftRightSection.additionalSection .textSection {
    padding-right: 0px;
    padding-left: 0px;
  }

  .webinarTitle {
    margin-top: 0px;
  }
}

@media (max-width: 900px) {
  .webinarSection {
    .cardContainer:last-of-type {
      margin-top: 24px;

      .card {
        &:first-of-type {
          top: 0px;
          margin-bottom: 24px;
          margin-left: 100px;
          margin-right: 0px;
        }

        &:last-of-type {
          left: 0px;
          margin-bottom: 24px;
          margin-right: 0px;
          margin-left: 100px;
        }

        &:nth-of-type(2) {
          left: 0px;
          margin-bottom: 24px;
          margin-right: 100px;
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .webinarTitle {
    .webinarLogoWrapper {
      &.multiLogoContainer {
        flex-direction: column;
      }

      .logoContainer:nth-of-type(n + 2) {
        margin-left: 0px;
      }

      .logo {
        margin-bottom: 16px;
      }
    }
  }

  .webinarShapeWrapper {
    top: 150px;
  }

  .webinarSection.leftRightSection {
    padding: 20px 20px 20px 20px;
    margin: 20px 20px 20px 20px;
  }

  .webinarSection.leftRightSection .shape1 {
    width: 100%;
    left: 0;
    top: -40px;
    opacity: 0.3;
  }

  .webinarInfo {
    flex-direction: column;

    .squareItem {
      justify-content: flex-start;
    }
  }

  .squareItem {
    margin: 0 0 10px 0;
    width: 100%;
  }

  .webinarMainContent.webinariumWPS {
    .webinarSection.leftRightSection .floatingItem1 {
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      display: none;
    }
  }

  .webinarMainContent.webinariumEXE {
    .webinarSection.leftRightSection .floatingItem1 {
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem2 {
      display: none;
    }

    .webinarSection.leftRightSection .floatingItem3 {
      display: none;
    }
  }

  .webinarSection {
    &.leftRightSection {
      .baseImg {
        width: 100%;
        max-width: 500px;
      }

      .floatingItem1 {
        left: 0;
      }

      .floatingItem2 {
        right: 0;
      }

      .floatingItem3 {
        right: 0;
      }
    }

    &.colaboration {
      .cardContainer:last-of-type {
        .card {
          &:first-of-type {
            top: 0px;
            margin-bottom: 24px;
            margin-left: 0px;
          }

          &:nth-of-type(2) {
            left: 0px;
            margin-bottom: 24px;
            margin-right: 0px;
            margin-left: 0px;
          }

          &:last-of-type {
            left: 0px;
            margin-right: 0px;
            margin-left: 0px;
          }
        }
      }
    }
  }

  .webinarTeam {
    .personIntroduction {
      .teamTextContainer {
        align-items: center;
        flex-direction: column;

        div {
          margin: 0 0 16px 0;
        }
      }
    }
  }

  .webinarContainer {
    .logo {
      width: 194px;
    }
  }

  .webinarFooter {
    .footerItem {
      .h4_btn {
        min-width: 202px;
      }
    }
  }
}

@media (min-width: 1023px) {

  .webinarium_atp .webinarSection.leftRightSection .textSection {
    padding-right: 50px;
    padding-left: 0px;
  }

  .webinarium_atp .webinarSection.leftRightSection.additionalSection .textSection {
    padding-right: 0px;
    padding-left: 50px;
  }
}