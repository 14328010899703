.successFormWrapper {
  .successHeader {
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: -0.4px;
    margin: 0 0 20px 0;
  }
  .text {
    margin: 0 auto 22px auto;
    max-width: 400px;

  }
  .successBtnWrap {
    margin: 30px auto 0 auto;
    max-width: 300px;
  }
}

.h4_contactContainer {
  .successFormWrapper {
    padding: 30px 0 0 0;
    .successHeader {
      text-align: left;
    }
    .text {
      margin: 0 0 22px 0;
    }
  }
}
.demoContainer {
  .successFormWrapper {
    padding: 30px 0 0 0;
    .successHeader {
      text-align: left;
    }
    .succesStepWrap {
      
    }
    .text.succesStep {
      margin: 0 0 16px 0;
    }
  }
}

@media (max-width:900px) {
  .h4_contactContainer {
    .successFormWrapper {
      padding: 30px 0 0 0;
    }
  }
}