.h4_contactContainer {
  padding: 50px 20px 100px 20px;
  position: relative;
  overflow: hidden;
  h1 {
    font-family: 'Euclid-SemiBold', 'Arial';
    font-size: 2.625rem;
    letter-spacing: -0.82px;
    margin: 0 0 80px 0;
    text-align: center;
  }
}

.contactBG {
  position: absolute;
  z-index: -1;
  left: -50%;
  top: -11%;
}

.contactWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contactFormWrap {
  width: 40%;
  padding: 0 40px 0 0;
  .buttonLight {
    margin: 30px 0 30px 0;
  }
  h2 {
    margin: 0 0 30px 0;
  }
}
.contactForm {
  padding: 30px 0 0 0;
  &.hideSups {
    sup {
      display: none;
    }
  }
}

.contactFromMap {
  width: 60%;
  margin: 0 0 0 40px;
  padding: 20px 0 0 0;
}

.requiredFieldsLegend {
  margin: 8px 0 16px 0;
  font-size: 14px;
  font-family: @euclidBold;
  sup {
    font-size: 10px;
    margin: 0 0 0 2px;
  }
}

.inputDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  position: relative;
  label {
    margin: 0 0 4px 0;
    font-family: @euclidBold;
  }
  sup {
    font-size: 10px;
    margin: 0 0 0 2px;
  }
  input,
  select {
    height: 44px;
    padding: 4px 8px;
    width: 100%;
  }
  textarea {
    padding: 4px 8px;
    width: 100%;
    height: 100px;
    resize: none;
  }
  input,
  textarea, 
  select {
    border: 1px solid rgba(15, 54, 92, 0.2);
    font-size: 16px;
    border-radius: 5px;
    font-family: @euclid;
    color: #0f365c;    
  }
  select {
    background-color: #fff;
    &.empty {
      color: grey;
    }
    option {
      color: black;
    }
    option:first-child {
      color: grey;
    }
    option[disabled]:first-child {
      display: none;
    }
  }
}

.contactItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;
  .squareIcon {
    margin: 0 10px 0 0;
  }
  a {
    color: @mainColor;
    text-decoration: underline;
  }
}

.formError {
  color: rgb(212, 16, 16);
  font-size: 12px !important;
  line-height: 1.3 !important;
  position: absolute;
  bottom: -14px;
  left: 0;
  display: none;
}
.notRequired {
  font-size: 0px;
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  overflow: hidden !important;
}

.contactFromMap {
  h2 {
    margin: 0 0 30px 0;
  }
  .google-maps {
    margin: 0 0 60px 0;
    #map {
      height: 500px;
    }
  }
}

.informationNotice {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 22px;
  max-width: 100%;
  display: block;
  font-size: small;
  margin: 0 0 20px 0;
  cursor: pointer;

  &.infoNoticeOpen {
    white-space: initial;
    max-height: initial;
    display: block;
    cursor: default;
  }
  a {
    text-decoration: underline;
  }
}

@media (max-width: 900px) {
  .h4_contactContainer h1 {
    margin: 0 0 50px 0;
  }
  .contactWrapper {
    flex-direction: column;
  }
  .contactFormWrap {
    width: 100%;
    padding: 0;
    margin: 0 0 60px 0;
  }
  .contactFromMap {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .contactBG {
    top: 0;
    width: 100%;
    left: 0;
  }
}
