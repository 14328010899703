.newsletterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  position: relative;
  margin: 0 auto 30px auto;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  color: #0f365c;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);
}

.newsletterWrapper {
  .newsletterContainer {
    display: none;
  }
}

#webinarSeeMore > p {
  display: none;
}

.newsletterHeader {
  position: relative;
  width: 100%;
}

.newsletterImage {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tick-bg {
  background: url(../../../assets/img/functions/icons/tick.svg);
  width: 50px;
  height: 50px;
  margin: 30px auto 0 auto;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsletterContainer h4 {
  position: relative;
  margin: 50px 0 50px;
  padding: 0px 20px;
  width: 100%;
  color: #fff !important;
  font-size: 26px;
  font-family: @euclidBold;
  line-height: 28px;
  letter-spacing: -0.51px;
  z-index: 1;
  text-align: center;
}

.newsletterContainer .newsletterInner h4 {
  margin: 0px 0 30px;
  color: #0f365c !important;
  font-size: 24px;
}

.newsletterContainer p {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.31px;
  text-align: center;
  &.informationNotice {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 22px;
    max-width: 100%;
    display: block;
    font-size: small;
    margin: 0 0 20px 0;
    cursor: pointer;
    &.infoNoticeOpen {
      white-space: initial;
      max-height: initial;
      display: block;
      cursor: default;
    }
    a {
      text-decoration: underline;
    }
  }
}

.inputWrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 0 16px 0;
  width: 100%;
}

.inputWrap .stdInput {
  padding: 15px;
  width: 100%;
  color: #0f365c;
  border: 1px solid #9daebe;
  border: 1px solid #9daebe;
  border-radius: 4px;
  border-radius: 4px;
  font-family: 'Euclid-Medium', 'Arial';
  font-size: 12px;
}

.inputWrap .stdInput::placeholder {
  color: rgba(15, 54, 92, 0.5);
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: -0.23px;
}

.inputWrap .buttonCTA {
  max-width: 300px;
}

.newsletterContainer {
  label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
  }
  .newsletterInner {
    padding: 15px 35px 35px 35px;
    width: 100%;
  }
  a {
    color: rgb(31, 138, 241);
  }
  a.buttonCTA {
    color: rgb(255, 255, 255);
  }
  label.control-checkbox {
    font-size: 12px;
    max-width: 520px;
  }
  .buttonCTA {
    margin: 0 auto;
    border: none;
    max-width: initial;
  }
}

.webinarSuccess {
  p {
    margin: 0 0 20px;
  }
  img {
    max-width: 300px;
    display: block;
    margin: 0 auto 30px auto;
  }
  .successDownload {
    margin: 40px auto;
  }
}

.newsletterText {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.31px;
  text-align: center;
}
.newsletterTextBold {
  font-size: 16px;
  font-family: @euclidBold;
  line-height: 20px;
  letter-spacing: -0.31px;
  text-align: center;
}

.accordionBtn {
  text-decoration: underline;
  cursor: pointer;
}

.accordionOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  .accordionInner {
    width: 100%;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-top: 40px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .webinarButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
    a {
      min-width: 235px;
      max-width: 235px;
      &:first-child {
        margin: 0 20px 0 0;
      }
      &:nth-child(3) {
        margin: 0 0 0 20px;
      }
      &:nth-child(4) {
        margin: 0 0 0 20px;
      }
    }
  }

  .heading {
    margin-bottom: 20px;
    text-align: center;
    text-align-last: center;
  }
}

@media (max-width: 991px) {
  .inputWrap .stdInput {
    font-size: 16px;
  }

  .newsletterContainer {
    overflow: hidden;
  }
}

@media (max-width: 820px) {
  .accordionOuter {
    .webinarButtonWrapper {
      flex-direction: column;
      margin: 32px 0px;
      a {
        &:first-child {
          margin: 0 0 20px 0;
        }
        &:nth-child(3) {
          margin: 20px 0 0 0;
        }
        &:nth-child(4) {
          margin: 20px 0 0 0;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .newsletterContainer h4 {
    font-size: 1.5rem;
  }
  .newsletterContainer .newsletterInner h4 {
    font-size: 1.25rem;
  }
  .webinarMainContent {
    padding: 50px 20px 32px 20px;
  }
}

@media (max-width: 480px) {
  .newsletterContainer h4 {
    margin: 30px 0px;
  }
}
@media (max-width: 400px) {
  .newsletterContainer {
    .newsletterInner {
      padding: 15px 20px 15px 20px;
    }
    .buttonCTA {
      padding: 20px 10px 20px 10px;
      max-width: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 350px) {
  .newsletterContainer h4 {
    margin: 15px 0px;
  }
  .newsletterContainer .buttonCTA {
    padding: 20px 0px 20px 0px;
    font-size: 12px;
  }
}
