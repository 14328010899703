.homeLeftRightSectionTitle {
  margin: 0 0 150px 0px;
  font-family: @euclidBold;
  font-size: 2.625em;
  letter-spacing: -0.82px;
  text-align: center;
}

.homeleftRighSectionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 70px;

  .leftRightSectionWrapper {
    display: flex;
    margin-bottom: 170px;
    width: 50%;

    .leftRightSection {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      position: relative;
      margin-bottom: 0px;
      max-width: 450px;
      & > div {
        width: 100%;
      }
      .leftRightSectionBgShape {
        position: absolute;
        top: -38%;
        right: -53%;
        transform: rotate(180deg);
        transform-origin: center center;
        width: 999px;
        z-index: -1;
      }
      .bgShape2 {
        top: 9%;
        right: -8%;
        transform: rotate(0deg);
      }
      .imgWrap {
        margin-bottom: 24px;
        height: 100%;
        max-height: 410px;
        width: auto;

        .shapeBase {
          position: absolute;
        }

        .homeShape1 {
          top: -21%;
          left: 51%;
          height: 190px;
          width: 190px;
        }
        .homeShape2 {
          top: 27%;
          left: 77%;
          height: 150px;
          width: 150px;
        }
        .homeShape3 {
          top: -41%;
          left: 102%;
          height: 350px;
          width: 350px;
        }
        .homeShape4 {
          top: -22%;
          left: 32%;
          height: 29px;
          width: 29px;
        }
        .homeShape5 {
          top: -8%;
          left: 43%;
          height: 100px;
          width: 100px;
          z-index: -1;
        }
        .homeShape6 {
          top: -19%;
          left: 34%;
          height: 170px;
          width: 170px;
        }
        .homeShape7 {
          top: 58%;
          left: 57%;
          height: 180px;
          width: 180px;
        }
        .homeShape8 {
          top: -14%;
          left: 29%;
          height: 33px;
          width: 33px;
          z-index: -1;
        }
        .homeShape9 {
          top: -24%;
          left: 39%;
          height: 180px;
          width: 180px;
          z-index: -1;
        }

        &.page {
          .mainImg {
            position: relative;
            top: -144px;
            left: -55px;
            height: 600px;
            width: auto;
          }
        }

        &.laptop {
          .mainImg {
            position: relative;
            top: 3px;
            left: -62px;
            height: 405px;
            width: auto;
          }
        }
      }
      .descriptionSection {
        width: 100%;
        z-index: 1;

        .listContainer {
          margin-top: 30px;
          width: 100%;

          .squareItem {
            margin-bottom: 16px;
            width: 100%;
          }
        }
      }
    }

    &:nth-child(2n) {
      justify-content: flex-end;
    }

    &:nth-child(n + 2) {
      margin-bottom: 0px;
    }

    &:nth-child(3) {
      .leftRightSection {
        .imgWrap {
          &.laptop {
            .mainImg {
              left: 0px;
            }
          }
        }
      }
    }
  }
}

.LRButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 160px;
  a {
    width: auto;
  }
}

@media (max-width: 1150px) {
  .homeLeftRightSectionTitle {
    margin: 0 0 80px 0px;
  }
  .homeleftRighSectionContainer {
    .leftRightSectionWrapper {
      margin-bottom: 0px;
      .leftRightSection {
        .leftRightSectionBgShape {
          top: -18%;
          right: -43%;
        }
        .bgShape2 {
          top: 9%;
          right: -8%;
          transform: rotate(0deg);
        }
        .imgWrap {
          min-height: 410px;

          .homeShape1 {
            top: -0%;
            left: 51%;
          }
          .homeShape2 {
            top: 47%;
            left: 71%;
          }
          .homeShape4 {
            top: -0%;
            left: 32%;
          }
          .homeShape5 {
            top: 12%;
            left: 43%;
          }
          .homeShape6 {
            top: 3%;
            left: 26%;
          }
          .homeShape7 {
            top: 66%;
            left: 44%;
          }
          .homeShape8 {
            top: 12%;
            left: 20%;
          }
          .homeShape9 {
            top: 2%;
            left: 29%;
          }

          &.page {
            .mainImg {
              top: 0px;
              left: -55px;
              height: 480px;
            }
          }

          &.laptop {
            .mainImg {
              top: 100px;
              left: -62px;
              height: 324px;
            }
          }
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        .leftRightSection {
          .imgWrap {
            &.page,
            &.laptop {
              .mainImg {
                left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .homeleftRighSectionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: 70px;
    .leftRightSectionWrapper {
      margin-bottom: 50px;
      width: 100%;
      .leftRightSection {
        width: 100%;
        max-width: 100%;
        .leftRightSectionBgShape {
          top: 3%;
          right: 50%;
          width: 80%;
        }

        .bgShape2 {
          top: 145%;
          right: 30%;
          width: 800px;
        }
        .imgWrap {
          margin-bottom: 0px;
          min-height: 1px;
          max-height: 370px;
          .homeShape1 {
            top: -29%;
            left: 55%;
            height: 190px;
            width: 190px;
          }
          .homeShape2 {
            top: 40%;
            left: 72%;
            height: 150px;
            width: 150px;
          }

          .homeShape4 {
            top: -26%;
            left: 39%;
            height: 29px;
            width: 29px;
          }
          .homeShape5 {
            top: -16%;
            left: 50%;
            height: 100px;
            width: 100px;
            z-index: -1;
          }
          .homeShape6 {
            top: -27%;
            left: 30%;
          }
          .homeShape7 {
            top: 57%;
            left: 48%;
          }
          .homeShape8 {
            top: -15%;
            left: 25%;
          }
          .homeShape9 {
            top: -27%;
            left: 32%;
          }

          &.page {
            .mainImg {
              display: block;
              top: -100px;
              left: 0px;
              margin: 0 auto;
              height: 480px;
            }
          }

          &.laptop {
            .mainImg {
              display: block;
              top: 0px;
              left: 0px;
              margin: 0 auto;
              height: 324px;
            }
          }
        }
      }

      &:nth-child(2n) {
        justify-content: flex-start;
      }
      &:nth-child(n + 2) {
        margin-bottom: 72px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 650px) {
  .homeShape1,
  .homeShape2,
  .homeShape3,
  .homeShape4,
  .homeShape5,
  .homeShape6,
  .homeShape7,
  .homeShape8,
  .homeShape9 {
    display: none;
  }

  .leftRightSectionBgShape {
    top: 3%;
    right: 50%;
    width: 400px;
  }

  .homeleftRighSectionContainer {
    .leftRightSectionWrapper {
      &:nth-child(3) {
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 500px) {
  .homeLeftRightSectionTitle {
    margin: 0px -10px;
    font-size: 2.25rem;
  }
  .homeleftRighSectionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: 70px;
    .leftRightSectionWrapper {
      .leftRightSection {
        .aos-init {
          margin-top: 0px;
        }
        .imgWrap {
          margin: 0 auto;
          &.page {
            .mainImg {
              top: 0px;
              left: 0px;
              height: auto;
              width: 100%;
              max-width: 400px;
            }
          }

          &.laptop {
            .mainImg {
              top: 0px;
              left: 0px;
              height: auto;
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }
  }
}
