#popupVideo {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
}
#overlayVideo {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(65, 64, 64, 0.562);
  opacity: 0;
  transition: 256ms opacity;
}

#popupVideoBody {
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  transition: 256ms opacity;
  max-width: calc(100% - 20px);
  min-width: 260px;
}

.popupHeader {
  font-family: 'Euclid-SemiBold', 'Arial';
  font-size: 1.5rem;
  letter-spacing: -0.66px;
  margin: 0 0 8px 0px;
  text-align: center;
}
.popupText {
  font-size: 1.125rem;
  font-family: 'Euclid-Regular', 'Arial';
  letter-spacing: -0.35px;
  max-width: 560px;
  text-align: center;
  margin: 0 0 8px 0;
}

.popupWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0 0;
  .buttonCTA {
    margin: 0 0 0 20px;
  }
  .h4_btn {
    padding: 12px;
    max-width: 180px;
    min-width: 180px;
  }
}

@media (max-width: 630px) {
  .popupWrap {
    flex-direction: column;
    .buttonCTA {
      margin: 10px 0 0 0 0;
    }
  }
}