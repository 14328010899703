.ebooki {
  padding: 50px 20px 145px;
  max-width: 1200px;
  margin: 0 auto;

  .mainHeader {
    margin-bottom: 60px;
    text-align: center;
  }

  .ebooksCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    align-items: stretch;
  }

  .ebooksCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 550px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);


    .ebooksCardHeader {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .name {
        text-align: center;

        p {
          margin: 10px 0;
          font-size: 24px;
          font-weight: bold;
          color: #333;
          word-wrap: break-word;
        }
      }

      .img_and_description {
        display: flex;
        gap: 20px;
        margin: 20px 0;
        align-items: center;
        height: 100%;

        .ebookImage {
          flex: 0 0 auto;

          img {
            width: 200px;
            height: auto;
            border-radius: 12px;
            object-fit: cover;
          }
        }

        .knowledge_description {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #474646;
          font-size: 1.13rem;
          letter-spacing: -0.5px;
          line-height: 1.5;
          font-weight: normal;
          text-align: left;
        }
      }
    }

    .downloadButton {
      margin-top: auto;
      display: flex;
      justify-content: center;

      a {
        display: inline-block;
        padding: 20px 40px;
        font-size: 18px;
        font-weight: bold;
        color: #009df5;
        background-color: rgba(53, 201, 255, 0.236);
        text-decoration: none;
        border-radius: 8px;
        transition: background-color 0.2s ease, opacity 0.2s ease;

        &:hover {
          background-color: #00a2ff;
          color: white;
          opacity: 1;
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .herobg {
    height: 600px;
    background-size: contain;
  }
}

.mainHeader {
  position: relative;
  z-index: 10;
}

// MEDIA QUERIES

@media (max-width: 1200px) {
  .ebooki {
    padding-top: 80px;

    .ebooksCardsContainer {
      justify-content: center;
      gap: 20px;
    }
  }
}

@media (max-width: 768px) {
  .ebooki {
    .ebooksCardsContainer {
      gap: 30px;
    }

    .ebooksCard {
      padding: 20px;
      max-width: 90%;
      height: auto; // wazne

      .ebooksCardHeader {
        .img_and_description {
          flex-direction: column;
          align-items: center;

          .ebookImage img {
            width: 150px;
            height: auto;
          }

          .knowledge_description {
            text-align: center;
            font-size: 1.1rem;
          }
        }

        .downloadButton {
          a {
            font-size: 16px;
            padding: 16px 32px;
          }
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .ebooki {
    .ebooksCard {
      .ebooksCardHeader {
        .img_and_description {
          .ebookImage img {
            width: 120px;
            height: auto;
          }

          .knowledge_description {
            font-size: 0.9rem;
          }
        }

        .downloadButton {
          a {
            font-size: 14px;
            padding: 12px 20px;
          }
        }
      }
    }
  }
}