@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

.leftRightSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 200px 0;

  > div {
    width: 50%;
  }
  .buttonLight {
    min-width: 224px;
    max-width: 224px;
    margin: 8px 0 0 0;
  }
}

.squareOuter {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 36px 0 0 0;
}

.squareItem {
  display: flex;
  align-items: center;
  width: calc(100% / 2);
  margin: 0 0 30px 0;
  &:nth-child(even) {
    .minorText {
      padding: 0;
    }
  }
}

.squareIcon {
  min-height: 55px;
  max-height: 55px;
  min-width: 55px;
  max-width: 55px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 25px 0 rgba(147, 200, 222, 0.37);
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
}

.floatingText {
  position: absolute;
  min-width: 130px;
  max-width: 150px;
  padding: 0px 5px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  background-color: #ffffff;
  box-shadow: 0 15px 29px 0 rgba(168, 194, 205, 0.59);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  img {
    width: 34px;
    height: 34px;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
  }
}

#marka-pracodawcy {
  position: relative;
  margin: 0;
  padding: 140px 0 0 0;
  .shape1 {
    position: absolute;
    top: -260px;
    left: -50%;
    transform: scaleX(-1) scaleY(-1);
    opacity: 0.3;
    z-index: -1;
  }
  .baseImg {
    width: 700px;
  }
  .baseImgWrap {
    position: relative;
    top: -115px;
    left: -24px;
  }
  .floatingItem1 {
    top: 160px;
    left: 41px;
  }
  .floatingItem2 {
    top: 63px;
    right: 30px;
  }
  .floatingItem3 {
    top: 430px;
    left: 214px;
  }
}

.floatingItem1,
.floatingItem2,
.floatingItem3 {
  animation: float 6s ease-in-out infinite;
}

.floatingItem1 {
  animation-delay: 1s;
  animation-duration: 7s;
}

.floatingItem2 {
  animation-delay: 666ms;
}

#pozyskiwanie-kandydatow {
  margin: -57px 0 0 0;
  .baseImg {
    width: 650px;
  }
  .baseImgWrap {
    position: relative;
    top: 20px;
    right: 184px;
  }
  .floatingItem1 {
    position: absolute;
    top: -30px;
    right: -47px;
  }
  .floatingItem2 {
    position: absolute;
    bottom: 46px;
    right: 224px;
  }
  .shape1 {
    position: absolute;
    right: 149px;
    width: 110px;
    top: -60px;
    z-index: -1;
    opacity: 0.2;
  }
  .shape2 {
    position: absolute;
    left: 47%;
    top: -100px;
    width: 27px;
  }
}

#selekcja-kandydatow {
  position: relative;
  margin: 142px 0 0px 0;
  .shape1 {
    position: absolute;
    top: -210px;
    right: -20px;
    opacity: 0.1;
    width: 120px;
  }
  .shape2 {
    position: absolute;
    top: -190px;
    right: -30%;
  }
  .baseImg {
    width: 700px;
  }
  .baseImgWrap {
    position: relative;
    top: -179px;
    left: -48px;
  }
  .floatingItem1 {
    top: 61%;
    left: 111px;
  }
  .floatingItem2 {
    top: 34%;
    right: -40px;
  }
}
#wspolpraca-z-biznesem {
  position: relative;
  margin: -79px 0 0 0;
  padding: 50px 0 0 0;
  .shape1 {
    position: absolute;
    bottom: -80px;
    left: -59%;
    z-index: -1;
    opacity: 0.8;
  }
  .shape2 {
    position: absolute;
    top: -120px;
    left: 50px;
    z-index: -1;
    width: 170px;
  }
  .baseImg {
    width: 650px;
  }
  .baseImgWrap {
    position: relative;
    top: -6px;
    right: 184px;
  }
  .floatingItem1 {
    position: absolute;
    top: -35px;
    right: -40px;
  }
  .floatingItem2 {
    position: absolute;
    bottom: 62px;
    right: 216px;
  }
}

#efektywnosc-rekrutacji {
  margin: 100px 0 0 0;
  position: relative;
  .shape1 {
    position: absolute;
    top: -14px;
    left: 160px;
    z-index: 1;
    width: 27px;
    opacity: 0.8;
  }
  .shape2 {
    position: absolute;
    top: -120px;
    left: 50px;
    z-index: -1;
    width: 170px;
  }
  .baseImg {
    width: 650px;
  }
  .baseImgWrap {
    position: relative;
    top: 4px;
    left: 114px;
  }
  .floatingItem1 {
    position: absolute;
    top: 43px;
    left: -33px;
  }
  .floatingItem2 {
    position: absolute;
    bottom: 62px;
    right: 216px;
  }
}
#bezpieczenstwo-i-rodo {
  margin: 114px 0 0 0;
  position: relative;

  .baseImg {
    width: 700px;
  }
  .baseImgWrap {
    position: relative;
    top: -137px;
    left: -120px;
  }
  .text {
    max-width: 510px;
  }
  .floatingItem1 {
    position: absolute;
    top: 111px;
    right: -23px;
    filter: drop-shadow(0 14px 29px rgba(168, 194, 205, 0.37));
  }
  .shape1 {
    position: absolute;
    bottom: 139px;
    left: -59%;
    z-index: -2;
    opacity: 0.8;
  }
  .shape2 {
    position: absolute;
    bottom: 150px;
    left: 50px;
    z-index: -1;
    width: 170px;
  }
  .shape3 {
    position: absolute;
    left: -130px;
    bottom: 35%;
    width: 80px;
    opacity: 0.2;
  }
  .shape4 {
    position: absolute;
    left: 30%;
    width: 110px;
    top: -160px;
    opacity: 0.2;
  }
}

@media (max-width: 1100px) {
  .functionContainer {
    overflow: hidden;
  }
}

@media (max-width: 1023px) {
  .functionContainer {
    padding-top: 80px !important;
  }
  #marka-pracodawcy,
  #selekcja-kandydatow,
  #efektywnosc-rekrutacji,
  #pozyskiwanie-kandydatow,
  #wspolpraca-z-biznesem,
  #bezpieczenstwo-i-rodo {
    .baseImgWrap {
      position: static;
      margin: 0;
    }
    .baseImg {
      width: 500px;
    }
  }

  #marka-pracodawcy,
  #selekcja-kandydatow,
  #efektywnosc-rekrutacji {
    flex-direction: column;
    margin: 0 0 100px 0;
    padding: 0;
  }

  #pozyskiwanie-kandydatow,
  #wspolpraca-z-biznesem,
  #bezpieczenstwo-i-rodo {
    flex-direction: column-reverse;
    margin: 0 0 100px 0;
    padding: 0;
  }

  .leftRightSection {
    .h4_btn {
      margin: 0 auto;
    }
    > div {
      width: 100%;
      &.aos-init {
        max-width: 500px;
        margin: 50px auto 0 auto;
      }
    }
  }

  #marka-pracodawcy {
    margin: 0;
    .baseImgWrap {
      position: relative;
      top: -50px;
      left: 0px;
    }
    .floatingItem3 {
      top: initial;
      bottom: 50px;
    }
  }

  #pozyskiwanie-kandydatow {
    margin: 0 0 70px 0;
    > div {
      &.aos-init {
        margin: 120px auto 0 auto;
      }
    }
  }
  #selekcja-kandydatow {
    margin: 0;
    .shape1 {
      top: -30px;
      right: 50px;
    }
    .shape2 {
      top: 0;
    }
    .floatingItem1 {
      left: 40px;
    }
    .floatingItem2 {
      right: 0;
    }
    > div {
      &.aos-init {
        margin: 0 auto;
      }
    }
  }

  #bezpieczenstwo-i-rodo {
    .floatingItem1 {
      right: 50px;
    }
    > div {
      &.aos-init {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 630px) {
  .leftRightSection .h4_btn {
    margin: 20px auto 0 auto;
  }
  .squareItem {
    width: 100%;
    margin: 0 0 10px 0;
  }
  #marka-pracodawcy,
  #selekcja-kandydatow,
  #efektywnosc-rekrutacji,
  #pozyskiwanie-kandydatow,
  #wspolpraca-z-biznesem,
  #bezpieczenstwo-i-rodo {
    .baseImg {
      width: 100%;
      max-width: 500px;
    }
  }
  #marka-pracodawcy {
    .floatingItem1 {
      left: 0;
    }
    .floatingItem2 {
      right: 0;
    }
    .floatingItem3 {
      right: 0;
    }
  }
  #pozyskiwanie-kandydatow {
    .floatingItem1 {
      right: 0;
    }
    .floatingItem2 {
      left: 0;
      right: initial;
    }
  }
  #wspolpraca-z-biznesem {
    .floatingItem2 {
      right: initial;
      left: 0;
      bottom: 30px;
    }
    .floatingItem1 {
      top: -5px;
      right: 0;
      left: initial;
    }
  }
  #efektywnosc-rekrutacji {
    .floatingItem1 {
      left: initial;
      right: 0;
    }
    .floatingItem2 {
      left: 0;
    }
  }
  #bezpieczenstwo-i-rodo {
    margin: 0 0 40px 0;
    .floatingItem1 {
      right: 50px;
      top: 20px;
    }
  }
}

@media (max-width: 360px) {
  .floatingItem1,
  .floatingItem2,
  .floatingItem3 {
    display: none;
  }
}
