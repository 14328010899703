.heroHome {
  padding: 83px 0 140px 0;
  position: relative;
  .h4_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }
  .h4_container.top {
    min-height: 449px;
  }

  .texth4_container {
    width: 50%;
    h1 {
      font-family: @euclidBold;
      font-size: 2.625rem;
      letter-spacing: -0.82px;
      margin: 0 0 31px 0;
    }
    p {
      opacity: 0.8;
      color: #0f365c;
      font-size: 1.375rem;
      letter-spacing: -0.43px;
      max-width: 400px;
      margin: 0 0 50px 0;
    }
  }

  .clientsContainer {
    border-top: 1px solid rgba(36, 147, 255, 0.15);
    border-bottom: 1px solid rgba(36, 147, 255, 0.15);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .clients {
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      padding: 20px;
      width: 100%;

      p {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.35px;
        line-height: 23px;
        margin: 0px 32px 0 0;
        white-space: nowrap;
      }

      .clientsListItem {
        max-width: 190px;
        margin: 0 50px 0 0;
        transition: 0.3s ease-in-out;
        filter: grayscale(100%);

        &:hover {
          filter: grayscale(0%);
        }

        &:last-of-type {
          margin: 0;
        }

        & img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.HomeImageContainer {
  width: 50%;
  position: relative;
  .laptop {
    max-width: 601px;
    position: relative;
    left: -91px;
    top: -9px;
    .laptopImg {
      width: 100%;
    }
  }
  .girl {
    position: absolute;
    top: -93px;
    max-width: 640px;
    left: 11px;
  }
}

.heroHome {
  .herobg {
    position: absolute;
    right: -70%;
    bottom: -270px;
    z-index: -1;
  }
  .shapeBase {
    position: absolute;
  }
  .shape1 {
    top: -50px;
    left: 122px;
    width: 18px;
  }
  .shape2 {
    bottom: 30%;
    top: initial;
    width: 60px;
    left: -60px;
    opacity: 0.1;
  }
  .shape3 {
    top: -40px;
    width: 65px;
    left: 31%;
    z-index: -1;
    opacity: 0.4;
  }
  .shape4 {
    right: -160px;
    top: 40px;
    opacity: 0.3;
  }
  .shape5 {
    right: -117px;
    top: 124px;
    left: initial;
    width: 37px;
    opacity: 0.6;
  }
  .shape6 {
    top: 38%;
    right: 22%;
    left: initial;
  }
  .shapeOutline {
    position: absolute;
    right: -70%;
    top: -50px;
    z-index: -1;
  }
}

@media (max-width: 1200px) {
  .heroHome {
    .herobg {
      bottom: -300px;
    }
  }
}

@media (max-width: 1100px) {
  .heroHome {
    .h4_container.top {
      min-height: 377px;
    }
  }

  .HomeImageContainer {
    .laptop {
      max-width: 480px;
      position: relative;
      left: -30px;
      top: -9px;
    }
    .girl {
      max-width: 520px;
      left: 90px;
    }
  }
}

@media (max-width: 1023px) {
  .heroHome {
    overflow: hidden;
    .h4_container.top {
      min-height: 753px;
    }
    .herobg {
      width: 100%;
      left: 0;
    }
    .top {
      flex-direction: column;
    }
    .texth4_container {
      width: 100%;
      position: relative;
      z-index: 1;
      h1 {
        max-width: 550px;
      }
    }
    .clientsContainer {
      .clients {
        flex-wrap: wrap;
        p {
          display: block;
          width: 100%;
          margin: 0;
          text-align: center;
        }

        .clientsListItem {
          margin: 0 auto;
          &:last-of-type {
            margin: 0 auto;
          }
        }
      }
    }
  }
  .HomeImageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0 0 0;

    .girl {
      left: initial;
      right: 0;
    }
    .herobg {
      right: -60%;
    }
  }
}

@media (max-width: 800px) {
  .heroHome {
    padding: 40px 0 140px 0;
    .h4_container.top {
      min-height: 753px;
    }
    .herobg {
      bottom: -160px;
    }
    .clientsContainer {
      border: none;

      .clients {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .HomeImageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0 0 0;

    .girl {
      max-width: 400px;
      top: -40px;
    }
  }
}

@media (max-width: 630px) {
  .heroHome {
    overflow: hidden;
    .h4_container.top {
      min-height: 721px;
    }
    .texth4_container {
      text-align: center;
      h1,
      p {
        margin: 0 auto 16px auto;
      }
      .h4_btn {
        margin: 0 auto;
      }
    }
  }
  .HomeImageContainer {
    margin: 40px 0 0 0;
    .girl {
      max-width: 330px;
      top: 40px;
      right: -60px;
    }
    .laptop {
      left: 0;
    }
  }
}

@media (max-width: 550px) {
  .heroHome {
    padding: 40px 0 60px 0;
    .h4_container.top {
      min-height: 721px;
    }
    .herobg {
      margin: 0 auto;
      display: block;
      width: calc(100% + 40px);
      left: -20px;
      right: initial;
    }
    .clientsContainer {
      .clients {
        margin: -30px 0 0 0;
        img {
          margin: 0;
        }
      }
    }
  }
  .HomeImageContainer {
    .girl {
      display: none;
    }
  }
}
@media (max-width: 480px) {
  .heroHome {
    padding: 40px 0 60px 0;
    .h4_container.top {
      min-height: 676px;
    }
  }
}
@media (max-width: 420px) {
  .heroHome {
    padding: 40px 0 60px 0;
    .h4_container.top {
      min-height: 676px;
    }
  }
}
