@baseFontSize: 16;

* {
  box-sizing: border-box;
}

html {
  font-size: @baseFontSize + 0px;
}

@media (max-width: 630px) {
  html {
    font-size: 14px;
  }
}

body {
  line-height: 1.3;
  padding: 0px;
  margin: 0px;
  color: @mainColor;
  position: relative;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  font-family: @euclid;
}

a {
  transition: 0.256s all;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 1.25em 0;
}

p {
  margin: 0;
  font-family: @euclidRegular;
}
