.demoContainer {
  position: relative;
  padding: 30px 20px 100px 20px;
  /*overflow: hidden;*/
  .demoLogoWrapper {
    text-align: center;
    margin-bottom: 30px;
  }
  h1 {
    margin: 0 0 30px 0;
    font-family: 'Euclid-SemiBold', 'Arial';
    font-size: 2.625rem;
    letter-spacing: -0.82px;
    /*text-align: center;*/
  }
  
  .leftRightSection {
    margin: 0;
    > div {
      width: 48%;

      .demoImageWrapper {
        width: 100%;
        margin-bottom: 16px;

        img {
          display: block;
          position: relative;
          top: 32px;
          height: auto;
          margin: 0 auto;
          width: 80%;
        }
      }
      input::placeholder {
        color: rgb(190, 190, 190);
      }
    }

    .textSection {
      ul {
        list-style: none;
        padding-left: 30px;
        margin: 0px 0px 14px 0px;

        .listItem {
          padding: 0px 0px 0px;
          color: rgba(15, 54, 92, 1);
          font-family: @euclidRegular;
          font-size: 1.125rem;
          line-height: 24px;
          letter-spacing: -0.35px;

          &:before {
            display: inline-block;
            margin-left: -30px;
            width: 30px;
            color: rgba(11, 157, 246, 1);
            content: '\2022';
            font-size: 2rem;
            position: relative;
            top: 3px;
          }
        }
      }
      .watchVideoLink a {
        color: #666;
        cursor: pointer;
      }
    }

    .teamTextContainer {
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
      >div {
        display: inline-block;
      }
      .teamImageWrapper {
        text-align: left;
      }
      .personImage {
        width: 140px;
        margin: auto;
        margin-right: 30px;
        float: left;      }
      .personDetails {
        float:left;
        margin-top: 12px;
        p {
          line-height: 1.8em;
        }
        .personIntroductionName {
            display: inline-block;
            font-size: x-large;
            margin: 2px 0px;
          }
        
          a.footerLinkedin {
            background: rgb(2, 157, 245);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            margin-left: 4px;
        
            img {
              width: 16px;
            }
        
          }
      }

    } 
  }
  .linkToContact {
    margin-top: 0.5em;
    a {
      text-decoration: underline;
    }
  }
  .buttonLight {
    min-width: 300px;
    max-width: 300px;
  }
  .requiredFieldsLegend {
    display: none;
  }
  .informationNotice {
  text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 22px;
    max-width: 100%;
    display: block;
    font-size: small;
    margin: 0 0 20px 0;
    cursor: pointer;
    color: #666;
  
    &.infoNoticeOpen {
      white-space: initial;
      max-height: initial;
      display: block;
      cursor: default;
    }
    a {
      text-decoration: underline;
    }
  }
  .successFormWrapper {
    .squareItem {
      width: 100%;
    }
    .text {
        margin: 0px 0px 0px 0px;
        max-width: unset;
      }
    .text a:hover {
      text-decoration: underline;
    }
  }
}

.demoBG {
  position: absolute;
  top: -50px;
  left: -40%;
  transform: scaleX(-1) scaleY(-1);
  opacity: 0.8;
  z-index: -1;
}

.demoShapeWrap {
  position: absolute;
  top: 7%;
  right: 10%;
  z-index: -1;
  .demoShape1 {
    position: absolute;
    top: 25px;
    left: -45px;
    opacity: 0.9;
  }
  .demoShape2 {
    position: absolute;
    bottom: -35px;
    left: -24px;
    width: 40px;
    opacity: 0.5;
  }
  .demoShape3 {
    position: relative;
    width: 60px;
    opacity: 0.3;
  }
}
.demoShapeWrap2 {
  position: absolute;
  bottom: 30%;
  left: 0;
  transform: rotate(245deg);
  z-index: -1;
  .demoShape1 {
    position: absolute;
    top: 25px;
    left: -45px;
    opacity: 0.9;
  }
  .demoShape2 {
    position: absolute;
    bottom: -35px;
    left: -24px;
    width: 40px;
    opacity: 0.5;
  }
  .demoShape3 {
    position: relative;
    width: 60px;
    opacity: 0.3;
  }
}

.demoCTA {
  margin-top: 80px;
  .footerInit {
    padding: 0px;
    .h4_container {
      display: flex;
      justify-content: center;

      .leftCTASection,
      .rightCTASection {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100%;
        min-height: 100px;
        width: 100%;
        max-width: 100%;

        & > div {
          width: 100%;
          max-width: 380px;
        }
      }

      .footerGirl {
        display: block;
        bottom: -180px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0px 0px 0px 0px;
      }
    }
  }
}

.formCardNoItem {
  padding-top: 20px;
}

.formCardItem {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 18px 7px rgba(57, 81, 94, 0.09);
}

.clientsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .clients {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    padding: 20px;
    width: 100%;

    p {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.35px;
      line-height: 23px;
      margin: 0px 32px 0 0;
      white-space: nowrap;
    }

    .clientsListItem {
      max-width: 190px;
      margin: 0 50px 0 0;
      transition: 0.3s ease-in-out;
      filter: grayscale(100%);

      &:hover {
        filter: grayscale(0%);
      }

      &:last-of-type {
        margin: 0;
      }

      & img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1023px) {
  .leftRightSection > div.aos-init.formCardItem {
    margin: 50px auto 20px auto;
  }
  .demoContainer {
    padding: 50px 20px 0px 20px;
    h1 {
      margin: 0 auto 16px auto;
    }
    .leftRightSection {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }
  .demoBG {
    width: 100%;
  }

  .clients {
    .clientsList {
      .clientsListItem {
        margin: 0px;
      }
    }
  }

  .demoCTA {
    .footerInit {
      .h4_container {
        flex-direction: column-reverse;

        .leftCTASection,
        .rightCTASection {
          margin: 0px;
          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:first-of-type {
              margin-bottom: 32px;
            }
            .heading,
            .text {
              text-align: center;
              text-align-last: center;
            }
          }
        }

        .footerGirl {
          left: 0;
          transform: translateX(0);
          margin: 0 auto;
        }
      }
    }
  }
  .clientsContainer {
    .clients {
      flex-wrap: wrap;

      p {
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
      }

      .clientsListItem {
        margin: 0 auto;

        &:last-of-type {
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .demoContainer {
    .leftRightSection {
      .textSection {
        .demoImageWrapper {
          margin-bottom: 0px;

          img {
            top: 16px;
          }
        }
        .squareOuter {
          margin: 36px 0 20px 0;
        }
      }

      .teamTextContainer {
        .teamImageWrapper {
          text-align: center;
          margin: 0px auto;

        .personImage {
            float: none;
          }
        
          .personDetails {
            float: none;
          }

        }
      }
    }
  }
  .demoCTA {
    margin-top: 0px;
  }
  .clientsContainer {
    border: none;

    .clients {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
}
